// Icon
$icon-error-red: #fe4f4f;
$icon-color-grey: #4d5665;
$icon-color-white: #cac9cc;

// Sidebar
$sb-icon-white: #ffffff;
$sb-icon-blue: #9eb4e6;
$sb-icon-red: #E04B5E;
$sb-selected-bg: #1B1D2C;
$bg-sidebar: #090916;
$bg-sidebar-button-gray: #1B1D2F;

//Right Sidebar
$bg-right-sidebar: rgba(9, 9, 22, 0.9);

// User Sidebar Menu
$sb-user-menu-bg: #1a1d2f;
$sb-user-menu-selected-bg: #303140;

// Background
$bg-bottom-popup-green: #2E7970;
$bg-bottom-popup-red: #8E313D;
$bg-bottom-popup-dark: #090916;

$bg-disclaimer-dark: #12101E;
$bg-disclaimer-light-gray: #1B1D2F;
$bg-disclaimer-scrollbar: #444655;

$bg-login-dark: #12101E;
$bg-login-light-gray: #1B1D2F;

$bg-user-account-grey: #1a1d2f;
$bg-user-acc-label-darker: #11101E;
$bg-user-acc-label-lighter: #1a1d2e;
$bg-user-acc-label-dark: #151322;
$bg-user-acc-social-grey: #1B1B2D;
$bg-user-acc-bottom-shadow: #11101E;
$bg-user-stat-grey: #1a1d2f;
$bg-user-stat-dark-grey: #171827;
$bg-user-notification-grey: #1a1d2f;
$bg-checkbox-checked: #404040;
$bg-user-cryptoacc-wallet-grey: #1a1d2f;
$bg-support-block-grey: #1a1d2f;
$bg-support-block-dark: #11101E;
$bg-support-block-light-grey: #2C2D3D;
$bg-user-guide-toggle-btn: #202334;
$bg-user-guide-step-white: #ffffff40;
$bg-error-red: #d84446;
$bg-status-ok: #5bd7ae;

$bg-darker: #090916;
$bg-dark: #141422;
$bg-light: #1a1d2e;
$bg-grey: #282b38;
$bg-selected: #1a1d30;
$gallery-grey-fade: #131525;
$bg-nft-gray: #393d4e;
$bg-nft-selected-gray: #2B2E3D;
$bg-quest-light-gray: #2B2E3D;
$bg-quest-page-gray: #1A1D2E;
$bg-prev-selected-nft-blue: #84C4FF;
$bg-generator-selector-top-grey: #1A1D2E;
$bg-generator-selector-gen-grey: #161826;
$fade-generator-selector: #131523;
$bg-assets-grey: #1a1d2d;
$bg-unstake-button-light-grey: #282B38;


//Icons
$fill-icon-gray: #4d5665;
$fill-icon-blue: #9eb4e6;
$fill-icon-yellow: #fec771;
$fill-icon-red: #e04b5e;
$fill-icon-white: #ffffff;


// Border
$border-disclaimer-white: #E5E7E8;
$border-user-account-icon: #0A0A15;
$border-user-account-active-input: #9fb4e6;
$border-user-account-error-input: #d84446;
$border-user-stat-light-grey: #5F606B;
$border-color-trans-white: #ffffff40;
$border-unstake-button-dark: #090916;

$border-grey: #333342;
$border-blue: #84C4FF;
$border-gold: #fec771;
$border-nft-active-grey: #545664;
$border-black: #000000;
$border-bluish-gray: #5c6387;
$border-nft-selected-gray: #2B2E3D;
$border-item-not-exist-red: #d84446;

// Text
$text-user-account-label-grey: #6B798B;
$text-user-account-label-error: #d84446;
$text-user-account-placeholder: #4c4a52;
$text-user-account-header-grey: #6D7A90;
$text-user-stat-grey: #545F70;
$text-user-notification-label-light-grey: #afadbc;
$text-user-cryptoacc-label-white: #9D9CA0;
$text-user-cryptoacc-value-white: #C3C2C5;
$text-support-input-grey: #afadbc;
$text-user-guide-toggle-btn: #9eb4e6;
$text-user-guide-block-header: #ffffff;
$text-user-guide-description: #bbbbbb;
$text-unstake-button-green: #5bd7ae;
$text-generator-selector-gen-title-gray: #6D7A90;
$text-generator-selector-gen-value-white: #dadada;
$text-quest-param-error: #d84446;
$text-shadow: #283d3f;
$text-quest-maker-grey: #6f7174;
$text-quest-maker-dark: #090916;
$text-nft-page-header-grey: #82838c;

$text-white: #dadada;
$text-grey: #b7b8bb;
$text-blue: #b1bee0;
$text-gold: #fec771;
$text-bluish-gray: #9eb4e6;
$text-azure: #14BBB6;

$link-gold: #fec771;

// Buttons
$btn-green-color-left: #00c899;
$btn-green-color-right: #00bdc5;

$btn-back-transparency: 0.22;
$btn-middle-transparency: 0.73;

//Generator
//Generator Modal Window
$modalBg: #ffffff;
$modalBgAlpa: 0.75;
$modalFontColor: #000000;