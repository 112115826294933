@use "app/styles/colors" as *;

.info-title {
  color: $text-white;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: 1.5rem;
}

.tag {
  display: inline-block;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: $bg-darker;
  margin: 0;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}