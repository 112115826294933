@use "app/styles/colors" as *;

.wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0.5rem;
  align-items: stretch;
}

.info-title {
  color: $text-white;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.info-param {
  color: $text-gold;
  font-size: 1.4rem;
  margin-right: 1rem;
}

.info-value {
  color: $text-white;
  font-size: 1.5rem;
  margin: 0;
}

.gained-items-list {
  margin-top: 1rem;

  >ul {
    list-style: none;
    padding: 0;
  }
}

.item-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  &>div {
    width: 7rem;
  }

  &>span {
    color: $text-white;
    font-size: 1.5rem;
  }
}

.empty {
  text-align: center;
}