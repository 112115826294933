@use "app/styles/colors" as *;

.notification-wrapper {
  display: grid;
  grid-template-columns: 2.5rem auto;
  column-gap: 2.8rem;
  padding-top: 1.9rem;
  padding-bottom: 2.5rem;
  padding-left: 2.8rem;
  padding-right: 2.6rem;
  background-color: $bg-user-notification-grey;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.tick-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.description-wrapper {
  >h3 {
    font-size: 2rem;
    margin: 0;
    color: $text-user-notification-label-light-grey;
    margin-bottom: 1.5rem;
    letter-spacing: -0.02rem;
    font-weight: 600;
  }

  >p {
    font-size: 1.6rem;
    margin: 0;
    color: $text-user-stat-grey;
    font-weight: 600;
  }
}