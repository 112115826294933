@use "app/styles/colors" as *;

.element-wrapper {
  display: grid;
  grid-template-rows: [row1-start] auto [row1-end row2-start] auto [row2-end row3-start] auto [row3-end row4-start] auto [row4-end];
  grid-template-columns: [col1-s] 90fr [col1-e col2-s] 20fr [col2-e];
  grid-template-areas:
    "title bookmarks"
    "quest-info heroes-play"
    "quest-info btn-block"
    "cost-reward btn-block";
  column-gap: 1rem;
  background-color: $bg-darker;
  border-radius: 0.3rem;
  padding: 2.8rem 1.8rem 1.8rem 1.8rem;
  border-left: 0.2rem solid $border-bluish-gray;
  transition: all 0.2s ease-in-out;

  &.compact {
    transition: all 0.2s ease-in-out;
    // grid-template-rows: [row1-start] auto [row1-end row2-start] 0 [row2-end row3-start] 0 [row3-end row4-start] auto [row4-end];
  }
}

.title {
  grid-area: title;
}

.bookmark {
  grid-area: bookmarks;
  justify-self: flex-end;
  margin-bottom: 0.5rem;
}

.info {
  position: relative;
  grid-area: quest-info;
  margin-bottom: 1.5rem;
  overflow: hidden;
  max-height: 50rem;
  transition: all 0.2s ease-in-out;
}

.cost-reward {
  grid-area: cost-reward;
}

.btn-block {
  grid-area: btn-block;
  // margin: 0.5rem 0;
}

.heroes-play {
  grid-area: heroes-play;
  max-height: 50rem;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}

.fold {
  max-height: 0;
  transition: all 0.2s ease-in-out;
  transform-origin: top;
  margin-bottom: 0;
}