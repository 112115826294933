@use "app/styles/colors" as *;

.info-title {
  color: $text-white;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.title-value {
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: 1.5rem;
  text-transform: uppercase;
}

.info-param {
  color: $text-gold;
  font-size: 1.4rem;
  margin-right: 1rem;
}

.info-value {
  color: $text-white;
  font-size: 1.4rem;
  margin: 0;
  text-transform: capitalize;
}