@use "app/styles/colors" as *;

.reset-btn {
  border: 0.1rem solid $border-grey;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  background-color: $bg-darker;

  &:hover {
    transform-origin: center;
    transition: all 0.1s ease-in-out;
    transform: scale(1.5);
  }
}

.colors {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: none;
  border-radius: 0.2rem;

  &.opened {
    gap: 0.2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.color {
  border: 0.1rem solid $border-grey;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.4);
    transition: all 0.1s ease-in-out;
    border: 0.1rem solid $border-gold;
  }
}

.reset {
  background-color: $bg-darker;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: tomato;
}