@use "app/styles/colors" as *;

.stat-block {
  width: 100%;

  >h3 {
    font-size: 2.1rem;
    color: $text-white;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
  }
}

.info-wrapper {
  padding: 1.5rem 1.6rem;
  background-color: $bg-user-stat-grey;
  display: grid;
  grid-template-columns: 14.3rem auto;
  column-gap: 1.5rem;
  border-radius: 0.5rem;
}

.info-first-block {
  >h4 {
    color: $text-user-stat-grey;
    margin: 0;
    margin-left: 0.3rem;
    margin-bottom: 0.6rem;
    font-size: 1.5rem;
  }

  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.3rem;
    color: $text-white;
    margin: 0;
    min-height: 5rem;
    border: 0.2rem solid $border-user-stat-light-grey;
    border-radius: 0.5rem;
  }
}

.info-second-block {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
}

.stat-row-element {
  >h4 {
    color: $text-user-stat-grey;
    margin: 0;
    margin-left: 1.7rem;
    margin-bottom: 0.6rem;
    font-size: 1.5rem;
  }

  >div {
    display: flex;
    align-items: center;
    font-size: 1.9rem;
    color: $text-white;
    margin: 0;
    padding: 0 1.8rem;
    min-height: 5rem;
    text-align: start;
    background-color: $bg-user-stat-dark-grey;
  }
}

.left {
  >div {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

.right {
  >div {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}