@use "app/styles/colors" as *;

.top-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.gen-stat {
  border-radius: 0.8rem;
  width: 34.6rem;
  padding-top: 1rem;
  padding-bottom: 0.6rem;
  background-color: $bg-generator-selector-top-grey;
}

.gen-title {
  margin: 0;
  padding: 0;
  text-align: center;
  color: $text-generator-selector-gen-title-gray;
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
}

.gen-value {
  margin: 0;
  padding: 0;
  text-align: center;
  color: $text-generator-selector-gen-value-white;
  font-size: 3.5rem;
}