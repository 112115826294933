@use "app/styles/colors" as *;

.open-gen-btn {
  background-color: $bg-grey;
  text-align: center;
  text-transform: uppercase;
  border: none;
  outline: none;
  margin-right: auto;
  min-width: 20rem;
  padding: 0.8rem 2.7rem;
  font-size: 2.5rem;
  color: $text-white;
  background-color: #1a1d2e;
  border-radius: 0.3rem;
  cursor: pointer;

  &:active {
    transform: translateY(0.2rem);
  }
}