@use "app/styles/colors" as *;

.wrapper {
  z-index: 2;
  position: fixed;
  padding: 0 2rem 0rem 8rem;
  bottom: 0;
  right: 0;
  left: 11rem;
  color: white;
  font-size: 5rem;
  height: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: flex;

  &.active {
    height: 8.5rem;
    padding: 2rem 2rem 2rem 8rem;
    transition: all 0.3s ease-in-out;
    background-color: $bg-bottom-popup-dark;
  }

  &.error {
    background-color: $bg-bottom-popup-red;
  }

  &.ok {
    background-color: $bg-bottom-popup-green;
  }
}