.page-title {
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 2rem;
  padding-left: 1rem;

  &>svg {
    height: 2.5rem;
  }
}