@use "app/styles/colors" as *;

.list-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  row-gap: 3rem;
}

.staked-element {
  background-color: $bg-nft-selected-gray;
}