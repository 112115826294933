@use "app/styles/colors" as *;

.stats-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  margin-bottom: 2rem;
}

.control-item {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-self: flex-end;
  // position: relative;
  // top: 3rem;
  // left: 0.5rem;
}

.buttons-title {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: $text-grey;
}