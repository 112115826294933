@use "app/styles/colors" as *;

.collections__wrapper {
  position: relative;
  min-width: 80rem;
  max-width: 136rem;
  padding: 3rem 0;
  margin-left: 5.5rem;
  display: flex;
  flex-direction: column;
}