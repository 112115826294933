@use "app/styles/colors" as *;

.control-item {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-self: flex-end;
  justify-content: flex-end;
}

.buttons-title {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: $text-grey;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 0.5rem;
}