@use "app/styles/colors" as *;

.fade-and-glow {
  position: relative;
}

.glow {
  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 50%;
    height: 50%;
    left: 25%;
    bottom: 25%;
    opacity: 0.3;
    border-radius: 50%;
    background-color: white;
    filter: blur(2rem);
    z-index: 1;
  }
}

.fade {
  &::after {
    display: block;
    position: absolute;
    content: "";
    width: 90%;
    height: 15%;
    bottom: 5%;
    left: 5%;
    background-color: $gallery-grey-fade;
    border-radius: 50%;
  }
}