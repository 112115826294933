@use "app/styles/colors" as *;

.unstake-timer-block {
  height: 4.5rem;
  margin: 0 1rem;
  background-color: $bg-darker;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
}

.unstake-text {
  display: inline-block;
  text-align: center;
  font-size: 1.5rem;
  color: $text-gold;
  font-weight: 400;
  margin: 0;
}