@use "app/styles/colors" as *;

.page-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 5.5rem;
  height: 100%;
  padding: 3rem 0;
  max-width: 136rem;
  min-width: 100rem;
}

.no-data {
  font-size: 2rem;
  color: $text-white;
  text-align: center;
}