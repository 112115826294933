@use "app/styles/colors" as *;

.cost-wrapper {
  width: 15rem;
  box-sizing: border-box;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid $border-grey;
  border-radius: 0.3rem;
  font-size: 1.5rem;
  color: $text-white;
  padding: 0 2.5rem;
}

.qst {
  margin-left: 0.5rem;
  color: $text-gold;
}