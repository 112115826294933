.root {
  // position: relative;
  width: 10vh;
  height: 10vh;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10rem;
}

.root img {
  width: 100%;
  height: 100%;
}

.empty {
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;

  div {
    width: 100%;
    height: 100%;
  }
}

.hero {
  width: 15vh;
  height: 15vh;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.inventory {
  width: calc(min(11rem, 10rem));
  height: calc(min(11rem, 10rem));
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.avatarsList {
  z-index: 9;
  left: 0;
  top: 0;
  border-radius: 5px;
  background: #000;
  display: grid;
  flex-wrap: wrap;
  position: absolute;
  grid-template-columns: repeat(4, 1fr);
}

.avatarSvg {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}

.inventoryList {
  z-index: 9;
  border-radius: 5px;
  background: #000;
  display: grid;
  flex-wrap: wrap;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;

  img {
    display: block;
  }

  &>div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &>div:hover {
    background-color: #2b2e3d;
  }
}

.clear {
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  left: 3px;
  top: 3px;
  position: absolute;
  // width: 20px;
  height: 20px;
  display: flex;
  background: #5c6387;
  align-items: center;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  z-index: 2;

  >span {
    color: red;
    margin-right: 1rem;
  }
}