@use "app/styles/colors" as *;

.modal-children {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  background-color: $modalBg;
  opacity: 0.75;
  color: $modalFontColor;
}