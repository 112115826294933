@use "app/styles/colors" as *;

.items-wrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: 1rem;
}

.item {
  width: 100%;
  background-color: $bg-nft-gray;
  border-radius: 0.3rem;
  cursor: pointer;
}

.active {
  outline: 0.5rem solid $border-nft-active-grey;
}

.item-empty {
  width: 100%;
  cursor: default;
}

.not-exist {
  outline: 0.3rem solid $border-item-not-exist-red;
}