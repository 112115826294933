@use "app/styles/colors" as *;

.modal-items__text {
  padding: 1rem;
  margin: 0;
  font-size: 2rem;
  color: $text-white;
  max-width: 50rem;
}

.modal-items__buttons {
  margin-top: 3rem;
  text-align: center;
}