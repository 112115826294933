@use "app/styles/colors" as *;

.generators-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin-bottom: 10rem;
}

.flip-icon {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  z-index: 1;
  fill: #4d5665;
  stroke: #4d5665;

  &:hover {
    cursor: pointer;
    fill: #fec771;
    stroke: #fec771;
  }
}

.select-btn-text {
  font-size: 1.8rem !important;
  padding: 0 2rem !important;
  color: $text-white !important;
}