@use "app/styles/colors" as *;
$max-width: 115rem;
// $max-width: 152rem;

.user-guide-block {
  max-width: $max-width;
  width: 88%;
  margin: 0;
  padding: 0;
  border-left: 1.5rem solid $border-color-trans-white;
  border-bottom: 1.5rem solid $border-color-trans-white;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.toggle-btn {
  background-color: $bg-user-guide-toggle-btn;
  padding: 0.8rem 0;
  color: $text-user-guide-toggle-btn;
  // font-size: 2rem;
  font-size: 1.7vh;
  text-transform: uppercase;
  border: none;
  outline: none;
  width: 17%;
  max-width: 26.8rem;
  min-width: 22rem;
  text-align: center;
  position: relative;
  cursor: pointer;

  >svg {
    display: inline-block;
    position: absolute;
    right: 2rem;
    top: 1rem;
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;

    &.open {
      transform: rotate(180deg);
      transition: all 0.2s ease-in-out;
    }
  }
}

.guide-wrapper {
  width: calc(100% + 1.5rem);
  max-width: calc($max-width + 1.5rem);
  position: relative;
  top: -1.5rem;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 0 100%, calc(100% - 1.5rem) 1.5rem, 1.5rem 100%, 100% 0;
  background-repeat: no-repeat;
  background-image:
    linear-gradient(0deg, $border-color-trans-white, $border-color-trans-white 0%, transparent 0%, transparent 100%, $border-color-trans-white 100%),
    // left
    linear-gradient(90deg, $border-color-trans-white, $border-color-trans-white 0%, transparent 0%, transparent calc(100% - 4.5rem), $border-color-trans-white 0),
    // top
    linear-gradient(180deg, $border-color-trans-white, $border-color-trans-white 6rem, transparent 6rem, transparent 100%, $border-color-trans-white 100%),
    // right
}

.main-guide {
  margin: 0;
  padding: 0;
  // margin-left: 9.2rem;
  margin-left: 5%;
  // padding-top: 2.9rem;
  padding-top: 2.5vh;
}

.btn {
  width: 30rem;
  min-width: 30rem;
  align-self: flex-end;
  padding-bottom: 1.5rem;
}

.steps {
  margin: 0;
  display: flex;
  flex-direction: row;
  margin-bottom: 10vh;
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $bg-user-guide-step-white;
  padding: 1.3rem 1.7rem;
  border-radius: 0.5rem;

  >h2 {
    font-family: "Futura";
    // font-size: 3.7rem;
    // line-height: 3.7rem;
    font-size: 1.3vw;
    line-height: 1.3vw;
    text-align: center;
    color: $text-user-guide-block-header;
    margin: 0;
    margin-bottom: 1.1rem;
    padding: 0;
    font-weight: 400;
    text-shadow: 1px 1px 1px $text-shadow;

    >svg {
      // width: 3.6rem;
      // height: 3.6rem;
      width: 1.5vw;
      height: 1.5vw;
    }
  }

  .screen {
    // height: 25rem;
    height: 8.5vw;
    // min-height: 15rem;

    >img {
      height: 100%;
    }
  }
}

.items-and-btn {
  display: flex;
  flex-direction: row;
  opacity: 1;
  transition: all 0.2s ease-out;

  &.hidden {
    opacity: 0;
    transition: all 0.2s ease-out;
  }
}

.avatar {
  width: 3vw;
  min-width: 3vw;
  margin: 0;
  margin-left: 2vw;

  >img {
    width: 100%;
  }
}

.items {
  display: grid;
  margin-left: 1vw;
  grid-template-columns: repeat(7, 3vw);
  column-gap: 0.5vw;
}

.item {
  align-self: flex-end;

  >img {
    width: 100%;
  }
}

.token-reward {
  flex-shrink: 0;
  height: 3vw;
  margin-left: 1vw;

  >img {
    width: 100%;
    height: 100%;
  }

  &:last-of-type {
    margin-left: 1rem;
  }
}

.second-guide {
  margin: 0;
  padding: 0;
  // padding-left: 9.2rem;
  padding-left: 5%;
  position: absolute;
  top: 1.5rem;
  left: 0;
  width: calc(100% - 1.5rem);
  height: 0;
  opacity: 0;
  overflow: hidden;
  background-color: $bg-user-guide-toggle-btn;
  transition: all 0.2s ease-out;
  z-index: 2;

  &.shown {
    height: 100%;
    opacity: 1;
    // padding-top: 3.6rem;
    padding-top: 1.5vh;
    transition: all 0.2s ease-out;
  }
}

.second-guide-inner {
  margin: 0;
  padding: 0;
  position: relative;

  >h3 {
    font-family: "Futura";
    // font-size: 3.1rem;
    font-size: 1.7vw;
    font-weight: 400;
    color: $text-user-guide-description;
    margin: 0;
    text-shadow: 1px 1px 1px $text-shadow;
  }

  >p {
    max-width: $max-width;
    width: 98%;
    // font-size: 1.7rem;
    font-size: 0.95vw;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.155rem;
    margin: 0;
    margin-top: 1.9rem;
    color: $text-user-guide-description;
  }
}

.close-btn {
  position: absolute;
  right: 0rem;
  top: -1rem;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: $text-user-guide-block-header;
  font-size: 2rem;

  >svg {
    width: 2.5rem;
  }
}

.steps-arrows {
  position: absolute;
  // width: 103rem;
  width: 48vw;
  top: 100%;
  right: -3vw;
  height: 1.5rem;
  background-color: $border-color-trans-white;
  margin-top: 4.6rem;
  margin-left: auto;
  margin-right: 1.5rem;
  margin-bottom: 1rem;

  >h3 {
    font-family: "Futura";
    text-align: center;
    // font-size: 3.1rem;
    font-size: 2.5vh;
    font-weight: 400;
    color: $text-user-guide-block-header;
    margin: 0;
    text-shadow: 1px 1px 1px $text-shadow;
  }
}

.arrow-right-block {
  display: flex;
  align-items: center;
  position: relative;
  align-self: center;
  top: 2rem;
  width: 5.5rem;
  height: 1.5rem;
  background-color: $border-color-trans-white;

  >svg {
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: -1rem;
  }
}

.arrow-down-block,
.arrow-up-block {
  position: absolute;
  top: 100%;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  height: 4.6rem;
  background-color: $border-color-trans-white;

  >div {
    display: flex;
    justify-content: center;
    align-items: center;

    >svg {
      position: absolute;
      width: 4rem;
      height: 4rem;
    }
  }
}

.arrow-down-block {
  >div {
    >svg {
      bottom: -1rem;
    }
  }
}

.arrow-up-block {
  >div {
    >svg {
      top: -1rem;
    }
  }
}

.arrow-top-down-block {
  position: absolute;
  width: 1.5rem;
  height: 6.5vh;
  left: calc(50% - 0.75rem);
  bottom: 100%;
  background-color: $border-color-trans-white;

  >div {
    display: flex;
    justify-content: center;

    >svg {
      width: 4rem;
      height: 4rem;
      position: absolute;
      bottom: -1rem;
    }
  }
}

.key-words {
  font-family: "Kohinoor";
  font-weight: 400;
}