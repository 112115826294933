@use "app/styles/colors" as *;

.create-account__wrapper {
  position: relative;
  margin: 0 auto;
  padding-top: 25rem;
  width: 700px;
  text-align: center;

  >a {
    position: absolute;
    top: 22rem;
    left: 0;
    color: $text-white;
    font-size: 1.8rem;
    cursor: pointer;
    text-decoration: none;

    >span {
      display: inline-block;
      vertical-align: 0.2rem;
      padding-right: 0.5rem;
    }
  }

  >h2 {
    color: $text-white;
    font-size: 3.5rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
  }

  >p {
    color: $text-bluish-gray;
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
    margin-bottom: 5rem;
  }
}

.create-account__warning {
  margin: 2rem 0 3rem;
}

.check-box__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.check-box__text {
  padding-left: 1.6rem;
  font-size: 1.7rem;
  line-height: 2.1rem;
  color: $text-white;
  font-weight: 400;
  letter-spacing: 0.025rem;
}