.wrapper {
  position: relative;
}

.rotating {
  position: absolute;
  left: 0;
  top: 0;
}

.percent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}