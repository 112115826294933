@use "app/styles/colors" as *;

.epoch-list {
  margin: 1rem 0;
}

.epoch-btns {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  justify-content: flex-start;
  column-gap: 1rem;
}

.add-epoch {
  cursor: pointer;
  font-size: 1.8rem;
  color: $text-white;
  border: 0.1rem solid $border-grey;
  border-radius: 0.3rem;
  background-color: $bg-bottom-popup-green;
  display: inline-block;
  padding: 0.5rem 1rem;
}

.load-btn {
  cursor: pointer;
  font-size: 1.8rem;
  color: $text-white;
  border: 0.1rem solid $border-grey;
  border-radius: 0.3rem;
  background-color: $bg-grey;
  display: inline-block;
  padding: 0.5rem 1rem;

  &>input {
    display: none;
  }
}

.save-btn,
.reset-btn {
  cursor: pointer;
  font-size: 1.8rem;
  color: $text-white;
  border: 0.1rem solid $border-grey;
  border-radius: 0.3rem;
  background-color: $bg-grey;
  display: inline-block;
  padding: 0.5rem 1rem;

  &.disabled {
    cursor: initial;
    background-color: $bg-dark;
    color: $text-grey;
  }
}

.reset-btn {
  background-color: $bg-bottom-popup-red;
}