$rotateY: 180deg;

.flip-wrapper {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(0);
  transition: 0.2s;
  cursor: pointer;
}

.flipped {
  transform: rotateY($rotateY);
  position: relative;
}

.front {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(0);
}

.back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY($rotateY);
}