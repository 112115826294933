@use "app/styles/colors" as *;

.top-bar__wrapper {
  position: fixed;
  width: calc(100% - 11.5rem);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.8rem 4rem;
  background-color: $bg-darker;
  border-left: 0.1rem solid $bg-light;

  >div:nth-child(1) {
    margin-right: 3.3rem;
  }

  >div:nth-child(2) {
    margin-right: 2rem;
  }

  >div:nth-child(3) {
    margin-right: 2rem;
  }

  >div:nth-child(4) {
    margin-right: 2.9rem;
  }
}