@use "app/styles/colors" as *;

.item-btn {
  outline: none;
  background-color: $bg-grey;
  font-size: 1.4rem;
  font-weight: 500;
  color: $text-grey;
  text-align: center;
  padding: 0.3rem 1rem;
  border-radius: 0.3rem;
  border: 0.1rem solid $bg-grey;
  cursor: pointer;

  &.selected {
    transition: all 0.1s ease-in-out;
    border: 0.1rem solid $border-gold;
  }
}