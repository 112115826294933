@use "app/styles/colors" as *;

.wallet-wrapper {
  display: grid;
  grid-template-columns: 18.7rem auto;
  column-gap: 1.2rem;
}

.wallet-amount {
  padding: 0;
  margin: 0;

  >h4 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    margin-bottom: 0.7rem;
  }

  >div {
    border-radius: 0.5rem;
    display: flex;
    min-height: 4.7rem;
    align-items: center;
    justify-content: center;
    color: $text-user-cryptoacc-value-white;
    border-width: 0.1rem;
    border-style: solid;
    font-size: 2.3rem;
  }
}

.wallet-address-and-btns {
  padding: 0;
}

.wallet-btns {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  display: flex;
  justify-content: flex-end;
  column-gap: 3rem;
  margin-right: 5.4rem;

  >button {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-weight: 600;
  }
}