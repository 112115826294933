@use "app/styles/colors" as *;

.fav-btn {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  opacity: 1;
  cursor: pointer;

  &:disabled {
    cursor: initial;
    opacity: 0.2;
  }
}

.btn-image {
  width: 100%;
  height: 100%;
}