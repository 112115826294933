@use "app/styles/colors" as *;

.pin-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 5rem);
  justify-content: center;
  column-gap: 2rem;
  margin-bottom: 5rem;
  margin-top: 3rem;
}

.pin-input {
  border: 0.2rem solid $border-grey;
  border-radius: 0.3rem;

  >input {
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
    outline-width: 0;
    align-items: center;
    border-width: 0;
    color: $text-white;
    background-color: transparent;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    height: 5rem;
    line-height: 100%;
    box-sizing: border-box;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}