@use "app/styles/colors" as *;

$thumb-radius: 1rem;

.container {
  position: relative;
  margin: 1.1rem 0.25rem;
  width: calc(100% - $thumb-radius * 2);
  transform: translateX($thumb-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  position: relative;
  width: calc(100%);
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 0.3rem;
  height: 0.5rem;
}

.slider__track {
  background-color: $text-grey;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: $border-gold;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: $text-grey;
  font-size: 1.6rem;
  margin-top: 1.6rem;
}

.slider__left-value {
  left: 0;
}

.slider__right-value {
  right: 0;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--left {
  z-index: 3;
  transform: translateX(-$thumb-radius);
}

.thumb--right {
  z-index: 4;
  transform: translateX($thumb-radius);
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: $border-grey;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0.1rem 0.1rem #ced4da;
  cursor: pointer;
  transform: translateY(0.2rem);
  height: calc($thumb-radius * 2);
  width: calc($thumb-radius * 2);
  pointer-events: all;
  z-index: 3;

  &:hover {
    background-color: $border-gold;
    outline: 0.3rem solid $border-color-trans-white;
  }
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: $border-grey;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0.1rem 0.1rem #ced4da;
  cursor: pointer;
  height: calc($thumb-radius * 2);
  width: calc($thumb-radius * 2);
  transform: translateY(0.2rem);
  pointer-events: all;
  z-index: 3;

  &:hover {
    background-color: $border-gold;
    outline: 0.2rem solid $border-color-trans-white;
  }
}