@use "app/styles/colors" as *;

.menu-item {
  text-decoration: none;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;

  &.selected {
    background-color: $sb-user-menu-selected-bg;

    >div>div>svg {
      fill: $sb-icon-white !important;
      stroke: $sb-icon-white !important;
      transition: all 0.7s ease-out;
    }
  }
}

.item-body {
  display: grid;
  grid-template-columns: 9rem auto;
  align-items: center;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: $sb-user-menu-selected-bg;

    .item-logo>svg {
      transition: all 0.1s ease-in-out;
    }
  }
}

.item-logo {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 2.4rem;
  transition: all 0.1s ease-in-out;

  svg {
    height: 100%;
  }
}

.item-text {
  padding: 0;
  margin: 0;
  color: $text-white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  opacity: 1;
  transition: all 0.3s ease-in-out;

  &.closed {
    transition: all 0.3s ease-in-out;
    opacity: 0;
    margin-left: 0;
  }
}