@use "app/styles/colors" as *;

.wrapper {
  margin: 10rem auto 0;
  width: 100rem;
}

.text {
  text-align: center;
  width: 100%;
  padding: 1rem;
  margin: 0;
  font-size: 2rem;
  color: $text-white;
}

.buttons {
  margin-top: 5rem;
  text-align: center;
}