@use "app/styles/colors" as *;

.preivew-wrapper {
  position: absolute;
  background-color: $bg-light;
  border-radius: 0.3rem;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: $text-white;
}

.preview-block {
  display: grid;
  height: 100%;
  grid-template-columns: minmax(5rem, 12rem) 85%;
  column-gap: 1rem;
}

.preview-image {
  align-self: flex-start;
  justify-content: center;
}

.avatar-img {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.preview-title {
  padding: 1rem;
  margin: 0;
}

.preview-info {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
}

.info-title {
  color: $text-white;
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.info-param {
  color: $text-gold;
  font-size: 1.4rem;
  margin-right: 1rem;
}

.info-value {
  color: $text-white;
  font-size: 1.4rem;
  margin: 0;
}