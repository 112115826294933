@use "app/styles/colors" as *;

.active-item-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.active-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  background-color: $bg-nft-selected-gray;
  padding: 0.5rem 1rem;
  cursor: pointer;

  >p {
    display: inline;
    font-size: 1.4rem;
    line-height: 1.4rem;
    margin: 0;
    padding: 0;
    color: $text-gold;
    text-transform: capitalize;
  }

  &>svg {
    display: inline-block;
    stroke: $text-gold;
    margin-left: 1rem;
    height: 1.4rem;
  }
}

.modal-block {
  width: 25rem;
}

.selector-modal {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.2rem;
  width: 100%;
  height: 0;
  overflow-y: hidden;
  background-color: $bg-grey;
  border-radius: 0.4rem;
  transition: all 0.2s ease-in-out;

  &.open {
    padding: 0.5rem;
    overflow-y: scroll;
    transition: all 0.2s ease-in-out;
    height: 25rem;
  }

  /* Width */
  scrollbar-width: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  scrollbar-color: $bg-disclaimer-scrollbar;

  &::-webkit-scrollbar-thumb {
    background: $bg-disclaimer-scrollbar;
    border-radius: 0.5rem;
  }
}

.selector-item {
  width: 100%;
  padding: 1rem;
  font-size: 1.4rem;
  color: $text-white;
  background-color: $bg-light;
  border-radius: 0.4rem;
  text-transform: capitalize;
  cursor: pointer;

  &.inactive {
    color: $text-grey;
    opacity: 0.5;
    cursor: initial;
  }
}

.toggle-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  color: $text-white;
  cursor: pointer;
  border-radius: 0.4rem;
  border: 0.1rem solid $border-grey;
}

.arrow {
  margin-left: auto;
  height: 1.6rem;
  stroke: $text-white;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;

  &.down {
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
  }
}