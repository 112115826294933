@use "app/styles/colors" as *;

.modal__children {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem 5rem;
  margin: 0;
  opacity: 1;
  background-color: $bg-light;
  z-index: 6;
}

.modal_transparent {
  background-color: transparent;
}

.modal__fade {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000000;
  opacity: 0.5;
  z-index: 5;
}