@use "app/styles/colors" as *;

.title-wrapper {
  text-align: center;
  padding: 0.7rem 0rem;
  background-color: $bg-light;
  border-radius: 0.3rem;
}

.entrance-title {
  font-size: 1.7rem;
  color: $text-gold;
  margin: 0;
  display: inline-block;
}

.reward-title {
  font-size: 1.7rem;
  color: $text-azure;
  margin: 0;
  display: inline-block;
}

.icon {
  width: 1.7rem;
  height: 1.7rem;
  margin-left: 1rem;
  vertical-align: -0.3rem;
  cursor: pointer;
}