@use "app/styles/colors" as *;

.wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0.5rem;
  align-items: stretch;
}

.info-title {
  color: $text-white;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.info-param {
  color: $text-gold;
  font-size: 1.4rem;
  margin-right: 1rem;
}

.info-value {
  color: $text-white;
  font-size: 1.4rem;
  margin: 0;
}

.copy-btn {
  cursor: pointer;
  height: 1.5rem;
  margin-left: 0.5rem;
  transition: all ease-out 0.1s;

  &.active {
    transition: all ease-out 0.1s;
    transform: scale(1.2);
  }
}

.link {
  color: $text-white;

  &:visited {
    color: inherit;
  }
}