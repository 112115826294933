@use "app/styles/colors" as *;

.heroes-play-block {
  margin: 0;
  padding: 0;
  padding-top: 2rem;
  padding-bottom: 1.4rem;
  background-color: $bg-quest-light-gray;
  border-radius: 0.5rem;
  text-align: center;
  margin-bottom: 1rem;

  >h3 {
    margin: 0;
    padding: 0;
    font-size: 1.7rem;
    font-weight: 400;
    color: $text-white;
    margin-bottom: 2.3rem;
  }

  >p {
    margin: 0;
    padding: 0;
    font-size: 3rem;
    font-weight: 400;
    color: $text-white;
  }
}