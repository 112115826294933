@use "app/styles/colors" as *;

.labeled-btn {
  border: none;
  outline: none;
  background-color: $bg-grey;
  color: $text-white;
  font-size: 1.6rem;
  text-align: center;
  padding: 0.6rem 1.3rem;
  border: 0.1rem solid $border-grey;
  border-radius: 0.3rem;
  height: 3.5rem;
  min-width: 13.5rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:disabled {
    cursor: initial;
    opacity: 0.5;
  }

  &.active {
    opacity: 1;
    cursor: pointer;

    &:active {
      transform: translate(-2%, 2%);
      box-shadow: 0.1rem 0.1rem 0.5rem $text-white;
      transition: all 0.1s ease-in-out;
    }
  }
}