@use "app/styles/colors" as *;

.col-selector-item {
  width: 14rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid $border-black;
  background-color: $bg-darker;
  padding: 1.6rem 1rem;
  color: $text-blue;
  font-size: 1.8rem;
  margin-right: 2.1rem;
  border-radius: 0.3rem;
}

.active {
  background-color: $bg-light;
  color: $text-gold;
}

.col-selector-item:hover {
  background-color: $bg-light;
}

.col-selector-img {
  height: 1.8rem;
  margin-right: 0.5rem;
}