@use "app/styles/colors" as *;

.gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}

.gallery-item {
  border: 0.5rem solid $bg-darker;
  background-color: $bg-darker;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;

  &.nft-previous-selected {
    outline: 0.1rem solid $border-blue;
  }

  &.item-selected {
    border-color: $border-nft-selected-gray;
    background-color: $bg-nft-selected-gray;
    // outline: none;
  }

  &.staked {
    background-color: $bg-darker;
    outline: 0.1rem solid $border-gold;
  }
}

.flip {
  width: 50%;
}

.info {
  width: 50%;
  overflow: hidden;
}

.empty-block {
  background-color: $bg-darker;
  margin: 5rem auto 0;
  width: 44rem;
  height: 18.5rem;
  position: relative;
}

.empty-bg {
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.empty-title {
  position: absolute;
  top: 5rem;
  font-size: 1.6rem;
  color: $text-white;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.empty-text-btn {
  position: absolute;
  width: 100%;
  bottom: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &>p {
    display: inline-block;
    font-size: 1.6rem;
    color: $text-grey;
    margin: 0;
    padding: 0;
    margin-right: 2rem;
  }
}