.unstake-wrapper {

  opacity: 0.3;

  &.active {
    cursor: pointer;
    opacity: 1;
  }

  >svg {
    width: 5.2rem;
  }
}

.waiting {
  animation: waiting 0.5s ease-in-out infinite;
  -webkit-animation: waiting 0.5s ease-in-out infinite;
}

@keyframes waiting {
  from {
    -webkit-transform: rotate(-30deg);
  }

  50% {
    -webkit-transform: rotate(30deg);
  }

  to {
    -webkit-transform: rotate(-30deg);
  }
}

@-webkit-keyframes waiting {
  from {
    -webkit-transform: rotate(-30deg);
  }

  50% {
    -webkit-transform: rotate(30deg);
  }

  to {
    -webkit-transform: rotate(30deg);
  }
}