@use "app/styles/colors" as *;

.send-hero-block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: $bg-nft-selected-gray;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
}

.send-hero-text {
  text-align: center;
  font-size: 1.7rem;
  color: $text-gold;
  font-weight: 400;
  margin: 0;
  // min-height: 3.5rem;
}

.hero-amount {
  text-align: center;
  font-size: 3rem;
  color: $text-white;
  font-weight: 400;
  margin: 0;
}

.btn {
  margin-top: 1.5rem;
  text-align: center;
}

.btn-style {
  font-size: 2rem !important;
  color: $text-white !important;
  padding: 0 0.5rem !important;
}