@use "app/styles/colors" as *;

.item-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  &>div {
    width: 7rem;
  }

  &>span {
    color: $text-white;
    font-size: 1.5rem;
  }
}