@use "app/styles/colors" as *;

.page-wrapper {
  width: 100%;
}

.info-block {
  width: 81.2rem;
  margin-left: 8rem;
  position: relative;
  top: -2.2rem;

  >h3 {
    margin: 0;
    padding: 0;
    color: $text-user-cryptoacc-label-white;
    font-size: 1.5rem;
    margin-bottom: 3.8rem;
  }
}

.support-block {
  padding: 0;
  margin: 0;
  background-color: $bg-support-block-grey;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}

.icon-title-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 2.4rem;
  align-items: center;
  padding: 1.3rem 2.2rem;
  cursor: pointer;
}

.icon-wrapper {
  margin: 0;
}

.title-wrapper {
  margin: 0;
  font-size: 1.9rem;
  color: $text-white;
  letter-spacing: 0.02rem;
}

.child-wrapper {
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.1s ease-in-out;

  &.open {
    max-height: 100vh;
    padding-bottom: 2rem;
    transition: all 0.1s ease-in-out;
  }
}

.arrow {
  height: 2.8rem;
  transform: rotate(0);
  transition: all 0.2s ease-in-out;

  &.down {
    transform: rotate(180deg);
  }
}

.form-wrapper {
  margin: 0;
  padding: 0 1.5rem;

  >h3 {
    margin: 0;
    margin-left: 6rem;
    padding: 0;
    font-size: 1.6rem;
    color: $text-support-input-grey;
    font-weight: 400;
    max-width: 48rem;
  }

  .form {
    margin-top: 2rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    border: none;

    >label {
      margin: 0;
      padding: 0;
      font-size: 1.5rem;
      color: $text-user-account-label-grey;
      width: 100%;
      display: block;
      margin-bottom: 0.6rem;

      >span {
        color: $text-user-account-label-error;
      }
    }

    >input {
      margin: 0;
      padding: 1.5rem;
      background-color: $bg-support-block-dark;
      border-radius: 0.5rem;
      color: $text-support-input-grey;
      font-size: 1.6rem;
      outline: none;
      border: none;
      font-size: 1.6rem;
      line-height: 1.6rem;
      height: 4.4rem;
      font-weight: 400;
      letter-spacing: 0.05rem;
      margin-bottom: 1.6rem;
    }

    >textarea {
      width: 100%;
      resize: vertical;
      min-height: 11rem;
      margin: 0;
      margin-bottom: 0.7rem;
      padding: 1.5rem;
      background-color: $bg-support-block-dark;
      border-radius: 0.5rem;
      color: $text-support-input-grey;
      font-size: 1.6rem;
      outline: none;
      border: none;
      font-size: 1.6rem;
      line-height: 1.6rem;
      font-weight: 400;
      letter-spacing: 0.05rem;

      /* Width */
      scrollbar-width: 0.5rem;

      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      scrollbar-color: $bg-disclaimer-scrollbar;

      &::-webkit-scrollbar-thumb {
        background: $bg-disclaimer-scrollbar;
        border-radius: 0.5rem;
      }
    }

    >p {
      margin: 0;
      margin-bottom: 3.2rem;
      // margin-left: 0.5rem;
      padding: 0;
      font-size: 1.5rem;
      color: $text-white;
      width: 100%;
      display: block;
      letter-spacing: 0.09rem;
    }
  }
}

.selector-wrapper {
  margin: 0;
  position: relative;
}

.selected {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;
  margin-bottom: 1.6rem;
  padding: 1.5rem;
  background-color: $bg-support-block-dark;
  border-radius: 0.5rem;
  color: $text-support-input-grey;
  font-size: 1.6rem;
  outline: none;
  border: none;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  cursor: pointer;
}

.arrow-small {
  position: absolute;
  right: 2.7rem;
  height: 2rem;
  transform: rotate(0);
  transition: all 0.2s ease-in-out;

  &.down {
    transform: rotate(180deg);
  }
}

.list {
  position: absolute;
  width: 100%;
  height: 0;
  top: 4.8rem;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  &.opened-list {
    height: calc(4.6rem * 6);

  }
}

.list-element {
  margin: 0;
  padding: 1.5rem;
  background-color: $bg-support-block-dark;
  border-radius: 0.5rem;
  color: $text-support-input-grey;
  font-size: 1.6rem;
  outline: none;
  border: none;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  cursor: pointer;

  &:hover {
    background-color: $bg-support-block-light-grey;
  }
}

.attach-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-bottom: 0.8rem;
  padding: 1.5rem;
  background-color: $bg-support-block-dark;
  border-radius: 0.5rem;
  color: $text-support-input-grey;
  font-size: 1.6rem;
  outline: none;
  border: 0.1rem solid transparent;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.05rem;

  &.drag {
    background-color: $bg-support-block-grey;
    border: 0.1rem solid $bg-support-block-dark;
  }

  >span {
    color: $text-bluish-gray;
    cursor: pointer;
    margin-right: 1rem;
  }

  #input-files {
    display: none;
  }
}

.attached-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 3.5rem;
  background-color: $bg-support-block-light-grey;
  margin-bottom: 0.5rem;

  >div {
    font-size: 1.5rem;
    color: $text-support-input-grey;
  }

  .file {
    width: 1.5rem;
    position: absolute;
    left: 1.5rem;
  }

  .delete {
    width: 1.5rem;
    position: absolute;
    right: 1.5rem;
    cursor: pointer;
  }
}

.btn-block {
  width: 28rem;
  margin: 0 auto;
}

.checkbox-block {
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;
  margin-left: 6rem;
  margin-bottom: 2.5rem;

  >label {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    color: $text-support-input-grey;
    width: 100%;
    display: block;
    margin-bottom: 0.6rem;
    margin-left: 1.3rem;
    letter-spacing: 0.08rem;
  }
}