@use "app/styles/colors" as *;

.options-btn {
  border: none;
  outline: none;
  background-color: $bg-grey;
  color: $text-white;
  font-size: 1.2rem;
  text-align: center;
  padding: 0.6rem 1.3rem;
  border: 0.1rem solid $border-grey;
  border-radius: 0.3rem;
  cursor: pointer;
}

.selected {
  border-color: $border-gold;
}