@use "app/styles/colors" as *;

.gen-info-wrapper {
  display: flex;
  flex-direction: column;
  width: 60rem;
  color: $text-generator-selector-gen-value-white;
  margin-bottom: 2rem;
}

.gen-title {
  font-family: "Din";
  padding: 0;
  margin: 0;
  font-size: 3rem;
  font-weight: 700;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid $border-grey;
}

.gen-stat {
  position: relative;
  display: grid;
  grid-template-columns: 10rem auto;
  column-gap: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid $border-grey;
}

.gen-logo {
  width: 10rem;

  >img {
    width: 100%;
  }
}

.gen-info {

  >p {
    font-weight: 400;
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
    margin-bottom: 2rem;

    >span {
      color: $text-gold;
      margin-left: 2rem;
    }
  }

  >ul {
    list-style: none;
    padding: 0;

    >li {
      font-weight: 400;
      font-size: 1.5rem;
      padding: 0;
      margin: 0.2rem 0;

      >span {
        color: $text-gold;
        margin-left: 2rem;
      }
    }
  }
}

.gen-reward-wrapper {
  position: absolute;
  right: 5rem;
  top: 50%;
  transform: translateY(-50%);
}

.gen-reward {
  position: relative;
  border-radius: 0.5rem;
  min-width: 20rem;
  padding: 2.5rem 2rem;
  border: 0.1rem solid $border-grey;
  text-align: center;
  align-self: center;
  font-size: 2.5rem;
  color: $text-bluish-gray;
  background-color: $bg-darker;

  >h3 {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    margin: 0;
    font-size: 1.6rem;
    font-weight: 700;
    color: $text-generator-selector-gen-value-white;

    >span {
      font-size: 1.3rem;
      margin-left: 0.3rem;
    }
  }
}

.gen-text {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid $border-grey;

  >h3 {
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-family: "Din";
  }

  >p {
    color: $text-generator-selector-gen-title-gray;
    font-size: 1.6rem;
    white-space: pre-wrap;
  }
}

.button {
  width: 30rem;
  margin-top: auto;
}