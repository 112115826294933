@use "app/styles/colors" as *;

// .avatar__wrapper {
//   display: block;
//   position: relative;
//   overflow: hidden;
//   width: 100%;
//   padding-bottom: 100%;
// }

// .image__body {
//   height: 100%;
//   position: absolute;
//   width: 100%;
//   z-index: 1;
// }

// .image__body.moved-to-left {
//   right: 14%;
// }

// .image__head {
//   position: absolute;
//   z-index: 1;
// }

.loading {
  position: absolute;
  transform-box: fill-box;
  transform-origin: 50%;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}