@use "app/styles/colors" as *;

.loading-wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  font-size: 1.6rem;
  font-weight: 400;
  color: $text-white;
  text-align: center;
  margin-bottom: 5rem;
}