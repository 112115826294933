.row {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  max-width: calc(100vw - 100px);
}

.selected-row {
  display: flex;
  flex-direction: row;
  max-width: calc(100vw - 100px);
  overflow: auto
}

.rowItem {
  svg {
    width: 10rem;
    height: 10rem;
  }
}

.selected-accessory {
  width: 10rem;
  height: 10rem;

  >img {
    width: 100%;
  }
}

.accessory {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid gray;

  img {
    width: 100%;
  }

  >p {
    position: absolute;
    bottom: 0;
    text-align: center;
    color: white;
    font-size: 15px;
    margin: 0;
    padding: 0;

    &:last-child {
      top: 0;
    }
  }
}

.active {
  margin: 10px;
  border: 2px solid green;
  border-radius: 3px;
}

.grid {
  margin-top: 30px;
  padding-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(7, 120px);
  column-gap: 10px;
  height: 50vh;
  overflow-y: auto;
}

.column {
  display: grid;
  grid-template-rows: repeat(64, 120px);
  row-gap: 10px;
}

.selectedHero {
  opacity: 1;
  max-height: 200px;
  max-width: 200px;
}