@use "app/styles/colors" as *;

.user-bar__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 1.1rem;
  margin-right: 1.6rem;
  overflow: hidden;
  min-width: 7.2rem;
  width: 7.2rem;
  height: 7.2rem;
  box-sizing: border-box;
  border: 0.5rem solid $border-grey;

  >div {
    width: 100%;
    height: 100%;

    >canvas {
      border: none;
    }
  }
}

.user-bar__name {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.6rem;
  color: $text-white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  opacity: 1;

  // for f**ing firefox shit
  max-width: 13rem;

  &.closed {
    opacity: 0;
    width: 0;
  }
}