@use "app/styles/colors" as *;

.page-wrapper {
  width: 100%;
}

.info-block {
  width: 81.7rem;
  margin-left: 8.5rem;
  position: relative;
  top: -2.2rem;

  >h3 {
    margin: 0;
    padding: 0;
    color: $text-user-account-header-grey;
    font-size: 1.5rem;
    margin-bottom: 3.5rem;
  }
}