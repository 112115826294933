@use "app/styles/colors" as *;

.unstake-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 2.5rem;
  line-height: 2.5rem;
  outline: none;
  font-size: 1.4rem;
  border: 0.1rem solid $border-unstake-button-dark;
  color: $text-unstake-button-green;
  background-color: $bg-unstake-button-light-grey;
  border-radius: 0.3rem;
  opacity: 0.6;
  transition: all 0.1s ease-in-out;

  &.active {
    opacity: 1;
    cursor: pointer;

    &:active {
      transform: translate(-2%, 2%);
      box-shadow: 0.1rem 0.1rem 0.5rem $text-white;
      transition: all 0.1s ease-in-out;
    }
  }
}