@use "app/styles/colors" as *;

.item__item {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  // padding-bottom: 100%;
}

.fade {
  &::after {
    display: block;
    position: absolute;
    content: "";
    width: 90%;
    height: 15%;
    bottom: 1.5rem;
    left: 5%;
    background-color: $gallery-grey-fade;
    border-radius: 50%;
  }
}

.loading {
  position: absolute;
  transform-box: fill-box;
  transform-origin: 50%;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}