@use "app/styles/colors" as *;

.add-btn {
  padding: 1rem;
  min-height: 30rem;
  border: 0.2rem solid $border-grey;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &>button {
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    font-size: 2.5rem;
    color: $text-white;
    font-weight: 500;
    background-color: transparent;
    border-radius: 50%;
    border: 0.2rem solid $border-grey;

    &:hover {
      border: 0.2rem solid $border-gold;
    }
  }
}

.epoch-remove {
  position: absolute;
  right: -1rem;
  top: -1rem;
  z-index: 1;
  border-radius: 50%;
}

.rem-epoch-btn,
.rem-season-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.1rem solid $border-grey;
  background-color: $bg-darker;
  color: tomato;
  font-size: 1.5rem;
  line-height: 2rem;
  transition: transform 0.1s ease-in-out;

  &:hover {
    transition: transform 0.1s ease-in-out;
    transform: scale(1.4);
    cursor: pointer;
  }
}

.epoch-id {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: flex-start;
  justify-content: center;
}

.row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 1.2rem;

}

.cell-input {
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  line-height: 100%;
  color: $text-white;
  font-weight: 500;
  padding: 0;
  margin: 0 auto;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;

  &.disabled {
    z-index: -1;
  }

  &.colored {
    color: $text-quest-maker-dark;
  }
}