@use "app/styles/colors" as *;

.wrapper {
  display: grid;
  grid-template-columns: 3rem auto;
  column-gap: 1rem;
}

.icon-wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 4.5rem;

  >img {
    width: 100%;
  }
}

.input-text-wrapper {
  width: 100%;
  margin-bottom: 3.2rem;

  >label {
    display: inline-block;
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;
    font-size: 1.5rem;
    color: $text-user-account-label-grey;
  }
}

.icon-error {
  fill: $icon-error-red;
  height: 2.1rem;
  width: 2.1rem;
  margin-left: auto;
}

.string-block {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  background-color: $bg-user-acc-label-dark;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;

  &.active {
    border: 0.1rem solid $border-user-account-active-input;
  }

  &:hover {
    border: 0.1rem solid $border-user-account-active-input;
  }

  >p {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: $text-white;
    font-weight: 600;
    letter-spacing: 0.05rem;
  }

  >input {
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: $text-white;
    font-weight: 600;
    letter-spacing: 0.05rem;
    width: 100%;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $text-user-account-placeholder;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $text-user-account-placeholder;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $text-user-account-placeholder;
    }
  }
}

.label-error {
  position: absolute;
  bottom: -2rem;
  left: 1.5rem;
  font-size: 1.3rem;
  color: $text-user-account-label-error;
  padding: 0;
  margin: 0;
}

.string-block-error {
  border: 0.1rem solid $border-user-account-error-input;
}