@use "app/styles/colors" as *;

.stats-item {
  display: flex;
  flex-direction: column;
  background-color: $bg-light;
  border: 0.1rem solid $bg-darker;
  padding: 1.7rem 2rem 1.7rem 2rem;
  border-radius: 0.5rem;
  min-width: 20.7rem;
  // max-width: 30.7rem;
  justify-content: space-between;
}

.stats-title {
  color: $text-grey;
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1.7rem;
}

.stats-value {
  display: flex;
  column-gap: 1.5rem;
  flex-direction: row;
  align-items: center;
  color: $text-white;
  font-size: 2.2rem;
  min-height: 3.5rem;

  >span {
    min-width: 4rem;
    letter-spacing: 0;
  }
}