@use "app/styles/colors" as *;

.side-nav__links-block {
  display: flex;
  flex-direction: column;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  margin-top: 1.8rem;

  &.bottom {
    padding-top: 3rem;
    border-top: 0.1rem solid $border-grey;
    flex: 1;

    >a {
      margin-bottom: 2rem;
    }
  }
}