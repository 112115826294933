@use "app/styles/colors" as *;
@use "app/styles/vars" as *;

.quest-element {
  margin-bottom: 3.5rem;
  min-height: 100%;
}

.quest-top-wrapper {
  width: 100%;
  background-color: $bg-quest-page-gray;
  padding-left: 5rem;
  padding-top: 2rem;
  padding-bottom: 0.3rem;

  &.on-top {
    position: sticky;
    top: 8rem;
    z-index: 2;
  }
}

.proper-nft-wrapper {
  box-sizing: content-box;
  min-width: $min-quest-block-width;
  max-width: $max-quest-block-width;
  padding-left: 5rem;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  padding-bottom: 5rem;
}

.quest-block {
  min-width: $min-quest-block-width;
  max-width: $max-quest-block-width;
}

.quest-inner-block {
  height: 100%;
  display: flex;
  flex-direction: column;
}