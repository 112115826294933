@use "app/styles/colors" as *;

.page-wrapper {
  width: 100%;
}

.info-block {
  width: 75.5rem;
  margin-left: 8.5rem;
  position: relative;
  top: -2rem;

  >h2 {
    margin: 0;
    padding: 0;
    color: $text-user-account-header-grey;
    font-size: 1.5rem;
    margin-bottom: 3.5rem;
  }
}

.top-stats {
  display: grid;
  grid-template-columns: repeat(3, 23.6rem);
  width: 100%;
  column-gap: 2.3rem;
  margin-bottom: 3.5rem;
}

.bottom-shadow {
  border-radius: 0.5rem;
  box-shadow: 0 0.8rem 0.1rem $bg-user-acc-bottom-shadow;
}