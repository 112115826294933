@use "app/styles/colors" as *;

.dash {
  padding: 0.5rem;
  font-size: 2rem;
  color: $text-white;
}

.input-block {
  flex: 1;

  &>input {
    font-size: 1.6rem;
    color: $text-white;
    background-color: $bg-light;
    padding: 0.5rem;
    border: 0.1rem solid $border-grey;
    border-radius: 0.3rem;
    width: 100%;

    &:focus {
      color: $text-gold;
      outline: 0.1rem solid $border-gold;
    }
  }

  &>input::-webkit-outer-spin-button,
  &>input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &>input[type=number] {
    -moz-appearance: textfield;
    // to fix warning:
    appearance: textfield;
  }
}