@use "app/styles/colors" as *;

.top-info-wrapper {
  position: fixed;
  display: grid;
  grid-template-columns: repeat(5, auto);
  top: -10rem;
  left: 11.5rem;
  width: calc(100% - 11.5rem);
  column-gap: 0.5rem;
  opacity: 0;
  z-index: 5;
  transition: all 0.3s ease-in-out;
  background-color: $bg-dark;
  border-bottom: 0.2rem solid $bg-light;
  // border-top: 0.2rem solid $bg-light;
  border-left: 0.2rem solid $bg-light;

  &.shown {
    top: 8rem;
    opacity: 1;
  }
}

.top-info {
  border-radius: 0.3rem;
  padding: 1rem 1rem;
  border-right: 0.1rem solid $border-grey;

  &:last-of-type {
    border-right: none;
  }
}

.title {
  color: $text-grey;
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 0;
  margin-right: 0.5rem;
}

.top-info__value {
  font-size: 1.5rem;
  font-weight: 400;
  color: $text-white;
  margin: 0;
  text-align: center;
}

.h2q {
  color: $text-bluish-gray;
  margin-left: 0.5rem;
}

.qst {
  color: $text-gold;
  margin-left: 0.5rem;
}