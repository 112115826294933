@use "app/styles/colors" as *;

.fold-btn {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  opacity: 1;
  cursor: pointer;
  transform: rotate(0);
  transition: 0.2s all ease-in-out;

  &:disabled {
    cursor: initial;
    opacity: 0.2;
  }

  &.turned {
    transform: rotate(180deg);
    transition: 0.2s all ease-in-out;
  }
}

.btn-image {
  width: 100%;
  height: 100%;
}