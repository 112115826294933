@use "app/styles/colors" as *;

.options-wrapper {
  margin-top: auto;
  min-height: 1.5rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}