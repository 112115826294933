.modal-buttons {
  display: grid;
  margin-top: auto;
  grid-template-columns: repeat(2, 15rem);
  column-gap: 2.5rem;
}

.modal-one-button {
  margin-top: auto;
  width: 75%;
  display: flex;
  justify-content: center;
}