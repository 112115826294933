@use "app/styles/colors" as *;

.page-wrapper {
  width: 100%;
}

.info-block {
  min-width: 100rem;
  max-width: 120rem;
  margin-left: 8.5rem;
  position: relative;
  top: -5.5rem;
}

.identicon {
  width: 12.5rem;
  height: 12.5rem;

  >canvas {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1rem solid $border-user-account-icon;
  }
}

.nickname-icon-block {
  display: grid;
  grid-template-columns: 12.5rem auto;
  column-gap: 1.8rem;
  margin-bottom: 3.2rem;
}

.icon-block {
  width: 100%;
}

.nickname-block {
  display: flex;
  flex-direction: column;

  >label {
    font-size: 1.5rem;
    color: $text-user-account-label-grey;
    padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  }

}

.nick-input-wrapper {
  width: fit-content;
  position: relative;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;

  &.active {
    border: 0.1rem solid $border-user-account-active-input;
  }

  &:hover {
    border: 0.1rem solid $border-user-account-active-input;
  }

  >input {
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1.6rem;
    height: 1.6rem;
    line-height: 1.6rem;
    color: $text-white;
    font-weight: 400;
    letter-spacing: 0.05rem;
    width: 23rem;
  }

  &:hover {
    .icon-edit {
      stroke: $icon-color-white;
    }
  }
}

.icon-edit {
  height: 2.1rem;
  width: 2.1rem;
  stroke: $icon-color-grey;
  margin-left: auto;
}

.icon-error {
  fill: $icon-error-red;
  height: 2.1rem;
  width: 2.1rem;
  margin-left: auto;
}

.icon-copy {
  margin-left: auto;
  cursor: pointer;
  height: 2.6rem;
  transition: all ease-out 0.1s;
  fill: $icon-color-grey;
  stroke: $icon-color-grey;

  &:hover {
    fill: $icon-color-white;
    stroke: $icon-color-white;
  }

  &.active {
    transition: all ease-out 0.1s;
    transform: scale(1.2);
  }
}

.address-block {
  width: 82rem;
  margin-bottom: 2.6rem;

  &:nth-child(4) {
    margin-bottom: 4.6rem;
  }

  >label {
    display: inline-block;
    margin-bottom: 0.5rem;
    padding-left: 0.3rem;
    font-size: 1.5rem;
    color: $text-user-account-label-grey;
  }
}

.readonly-string-block,
.string-block {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.4rem 1.5rem;
  background-color: $bg-user-acc-label-darker;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;

  >p {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: $text-white;
    font-weight: 600;
    letter-spacing: 0.05rem;
  }

  >input {
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: $text-white;
    font-weight: 600;
    letter-spacing: 0.05rem;
    width: 90%;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $text-user-account-placeholder;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $text-user-account-placeholder;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $text-user-account-placeholder;
    }
  }
}

.string-block {
  &.active {
    border: 0.1rem solid $border-user-account-active-input;
  }

  &:hover {
    border: 0.1rem solid $border-user-account-active-input;
  }
}

.label-error {
  position: absolute;
  bottom: -2rem;
  left: 1.5rem;
  font-size: 1.3rem;
  color: $text-user-account-label-error;
  padding: 0;
  margin: 0;
  width: 50rem;
}

.string-block-error {
  border: 0.1rem solid $border-user-account-error-input;
}

.socials-wrapper {

  >p {
    margin: 0;
    font-size: 2.5rem;
    color: $text-white;
    margin-bottom: 0.6rem;
    padding-left: 0.8rem;
    letter-spacing: 0.1rem;
  }
}

.socials {
  padding: 1.7rem 2.6rem;
  background-color: $bg-user-acc-social-grey;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3.5rem;
}

.bottom-message-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.bottom-shadow {
  border-radius: 0.5rem;
  box-shadow: 0 0.8rem 0.1rem $bg-user-acc-bottom-shadow;
}