@use "app/styles/colors" as *;

.statuses {
  position: absolute;
  right: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
}

.status {
  width: 1rem;
  height: 1rem;
  background-color: $bg-error-red;
  border-radius: 50%;
  padding: 0;
  margin: 0;

  &.active {
    background-color: $bg-status-ok;
  }
}