@use "app/styles/colors" as *;

$selector-size: 1.4rem;

.cell-wrapper {
  position: relative;
  padding-bottom: 100%;
}

.deployed-indicator {
  position: absolute;
  left: 0.1rem;
  bottom: 0.1rem;
  width: calc($selector-size / 2);
  height: calc($selector-size / 2);
  border-radius: 50%;
  background-color: $bg-error-red;
}

.cell-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.cell {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border-radius: 0.5rem;
  border: 0.1rem solid $border-grey;
  text-align: center;

  &:hover {
    border: 0.1rem solid $border-gold;
  }

  &.error {
    outline: 0.2rem solid red;
  }

  &.active {
    border: 0.1rem solid $border-gold;
  }
}

.color-selector {
  position: absolute;
  // - (circle size - 2 * border) / 2
  right: calc(-1 * ($selector-size - 2 * 0.1rem)/2);
  bottom: calc(-1 * ($selector-size - 2 * 0.1rem)/2);
  width: $selector-size;
  height: $selector-size;
  border: 0.1rem solid $border-grey;
  border-radius: 50%;
}

.sup-color-selector {
  position: absolute;
  left: calc(-1 * ($selector-size - 2 * 0.1rem)/2);
  top: calc(-1 * ($selector-size - 2 * 0.1rem)/2);
  width: $selector-size;
  height: $selector-size;
  border: 0.1rem solid $border-grey;
  border-radius: 50%;
}