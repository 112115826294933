@use "app/styles/colors" as *;
$max-width: 115rem;
// $max-width: 152rem;

.user-guide-block {
  position: relative;
  margin: 0;
  padding: 0;
  max-width: $max-width;
  width: 88%;
  border-left: 1.5rem solid $border-color-trans-white;
  border-bottom: 1.5rem solid $border-color-trans-white;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-bottom: 1.9vh;
}

.toggle-btn {
  background-color: $bg-user-guide-toggle-btn;
  padding: 0.8rem 0;
  color: $text-user-guide-toggle-btn;
  // font-size: 2rem;
  font-size: 1.7vh;
  text-transform: uppercase;
  border: none;
  outline: none;
  width: 17%;
  max-width: 26.8rem;
  min-width: 22rem;
  text-align: center;
  position: relative;
  cursor: pointer;

  >svg {
    display: inline-block;
    position: absolute;
    margin: 0;
    right: 2rem;
    top: 1rem;
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;

    &.open {
      transform: rotate(180deg);
      transition: all 0.2s ease-in-out;
    }
  }
}

.guide-wrapper {
  width: calc(100% + 1.5rem);
  max-width: calc($max-width + 1.5rem);
  position: relative;
  top: -1.5rem;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 0 100%, calc(100% - 1.5rem) 1.5rem, 1.5rem 100%, 100% 0;
  background-repeat: no-repeat;
  background-image:
    linear-gradient(0deg, $border-color-trans-white, $border-color-trans-white 0%, transparent 0%, transparent 100%, $border-color-trans-white 100%),
    // left
    linear-gradient(90deg, $border-color-trans-white, $border-color-trans-white 0%, transparent 0%, transparent calc(100% - 4.5rem), $border-color-trans-white 0),
    // top
    linear-gradient(180deg, $border-color-trans-white, $border-color-trans-white 6rem, transparent 6rem, transparent 100%, $border-color-trans-white 100%),
    // right
}

.main-guide {
  margin: 0;
  padding: 0;
  // margin-left: 9.2rem;
  margin-left: 5%;
  // padding-top: 4.6rem;
  padding-top: 3vh;

  >h3 {
    font-family: "Futura";
    // font-size: 3.1rem;
    font-size: 1.7vw;
    font-weight: 400;
    color: $text-user-guide-block-header;
    margin: 0;
    text-shadow: 1px 1px 1px $text-shadow;
  }

  >p {
    max-width: $max-width;
    width: 98%;
    // font-size: 1.7rem;
    font-size: 0.95vw;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.155rem;
    margin: 0;
    margin-top: 1.5rem;
    color: $text-user-guide-block-header;
  }
}

.btn {
  width: 30rem;
  min-width: 30rem;
  align-self: flex-end;
  padding-bottom: 1.5rem;
}

.items-and-btn {
  display: flex;
  flex-direction: row;
  // margin-top: 1.6rem;
  margin-top: 1.5vh;
  opacity: 1;
  transition: all 0.2s ease-out;

  &.hidden {
    opacity: 0;
    transition: all 0.2s ease-out;
  }
}


.avatar {
  width: 4vw;
  margin: 0;
  margin-left: 5.5%;

  >img {
    width: 100%;
  }
}

.items {
  display: grid;
  margin-left: 0.35vw;
  // grid-template-columns: repeat(7, 10.85rem);
  grid-template-columns: repeat(7, 4vw);
  // column-gap: 1.2rem;
  column-gap: 0.35vw;
}


.item {
  margin: 0;
  align-self: flex-end;

  >img {
    width: 100%;
  }
}

.second-guide {
  margin: 0;
  padding: 0;
  // padding-left: 9.2rem;
  padding-left: 5%;
  position: absolute;
  top: 1.5rem;
  left: 0;
  width: calc(100% - 1.5rem);
  height: 0;
  opacity: 0;
  overflow: hidden;
  background-color: $bg-user-guide-toggle-btn;
  transition: all 0.2s ease-out;
  z-index: 2;

  &.shown {
    height: 100%;
    opacity: 1;
    // padding-top: 3.6rem;
    padding-top: 1.5vh;
    transition: all 0.2s ease-out;
  }
}

.second-guide-inner {
  margin: 0;
  padding: 0;
  position: relative;

  >h3 {
    font-family: "Futura";
    // font-size: 3.1rem;
    font-size: 1.7vw;
    font-weight: 400;
    color: $text-user-guide-description;
    margin: 0;
    text-shadow: 1px 1px 1px $text-shadow;
  }

  >p {
    max-width: $max-width;
    width: 95%;
    // font-size: 1.7rem;
    font-size: 0.95vw;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.155rem;
    margin: 0;
    margin-top: 1.9rem;
    color: $text-user-guide-description;
  }
}

.close-btn {
  position: absolute;
  right: 0rem;
  top: -1rem;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: $text-user-guide-block-header;
  font-size: 2rem;

  >svg {
    width: 2.5rem;
  }
}

.key-words {
  font-family: "Kohinoor";
  font-weight: 400;
}