@use "app/styles/colors" as *;

.slider__buttons {
  cursor: pointer;
}

.btn-left {
  padding-right: 2.5rem;
}

.btn-right {
  padding-left: 2.5rem;
}

.slider__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $bg-light;
  padding: 5rem;
}

.slider__active-window {
  border: 0.5rem solid $bg-darker;
  overflow: hidden;
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.05);
}

.slider__elements {
  display: flex;
  flex-direction: row;
  transition: translate;
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.no-avatars {
  color: $text-white;
  display: flex;
  align-items: center;

  >p {
    padding: 1rem;
    max-width: 100%;
    width: 100%;
    text-align: center;
    line-height: 2rem;
    font-size: 1.5rem;
  }
}