@use "app/styles/colors" as *;

.filter-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0.5rem 0;
  padding: 1rem 0;
}

.toggle-filter-wrapper {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  border: 0.1rem solid $border-grey;

  >p {
    display: inline;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: $text-blue;
    padding: 0;
    margin: 0;
  }
}

.icon {
  display: inline-block;
  height: 2.5rem;
  stroke: $text-blue;

  &.active {
    stroke: $text-gold;
  }
}