.chat-wrapper {

  opacity: 0.3;

  &.active {
    cursor: pointer;
    opacity: 1;
  }

  >svg {
    width: 3.2rem;
  }
}