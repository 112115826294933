@use "app/styles/colors" as *;

.avatar-gallery__wrapper {
  margin-top: 2rem;
  padding-bottom: 3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.5rem;
}

.empty-gallery {
  padding: 5rem;
  color: $text-white;
  font-size: 2rem;
  width: 50rem;
  margin: 0 auto;
  text-align: center;
}