@use "app/styles/colors" as *;

.side-widget {
  position: fixed;
  overflow-y: scroll;
  right: 0;
  top: 8rem;
  transform-origin: right;
  transform: translateX(100%);
  padding: 2rem;
  opacity: 0;
  height: calc(100% - 8rem - 1.6rem);
  background-color: $bg-right-sidebar;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  transition-property: opacity, translate;
  transition: 0.5s ease-in-out;
  z-index: 1;

  &.open {
    transform: translateX(0%);
    opacity: 1;
    transition: 0.5s ease-in-out;
  }

  .side-child {
    width: 33rem;
  }

  /* Width */
  scrollbar-width: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  scrollbar-color: $bg-disclaimer-scrollbar;

  &::-webkit-scrollbar-thumb {
    background: $bg-disclaimer-scrollbar;
    border-radius: 0.5rem;
  }
}

.close-btn {
  align-self: flex-end;
  width: 3rem;
  height: 3rem;
  min-height: 3rem;
  cursor: pointer;
}