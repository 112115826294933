@use "app/styles/colors" as *;

.seed-words-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 18rem);
  justify-content: center;
  column-gap: 2rem;
  row-gap: 2rem;
  margin-bottom: 5rem;
}

.seed-word-input {
  border: 0.2rem solid $border-grey;
  border-radius: 0.3rem;

  >input {
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
    outline-width: 0;
    align-items: center;
    border-width: 0;
    color: $text-white;
    background-color: transparent;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    height: 3.3rem;
    line-height: 100%;
    box-sizing: border-box;
  }
}