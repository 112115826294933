@use "app/styles/colors" as *;

$icon-size: 2rem;
$left-offset: 1.2rem;
$right-offset: 1.2rem;
$icon-offset: 0.5rem;

.generator-buttons {
  position: relative;
  background-color: $bg-darker;
  padding: 0.7rem 0 1.2rem 0;
  display: flex;
  align-items: center;
  cursor: initial;
}

.info-title {
  font-family: "Din";
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
  color: $text-white;
  font-size: 2.2rem;
}

.play-icon,
.assets-icon,
.nft-icon {
  width: $icon-size;
  height: $icon-size;
  position: absolute;

  &.active {
    cursor: pointer;

    &:hover {
      fill: $fill-icon-yellow;
    }
  }
}

.assets-icon {
  left: $left-offset;
}

.nft-icon {
  left: calc($left-offset + $icon-size + $icon-offset);
}

.play-icon {
  right: $right-offset;
  width: calc($icon-size * 1.3);
  height: calc($icon-size * 1.3);
}