@use "app/styles/colors" as *;

.page-wrapper {
  width: 100%;
}

.info-block {
  width: 82rem;
  margin-left: 8.5rem;
  position: relative;
  top: -2.2rem;

  >h3 {
    margin: 0;
    padding: 0;
    color: $text-user-account-header-grey;
    font-size: 1.5rem;
    margin-bottom: 2.1rem;
  }
}

.two-address-block {
  margin-bottom: 3.3rem;

  >h2 {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    color: $text-white;
    margin-bottom: 0.2rem;
  }
}

.two-address {
  padding: 0.8rem 2.5rem 1.5rem 2.5rem;
  border-radius: 0.5rem;
}

.bottom-shadow {
  border-radius: 0.5rem;
  box-shadow: 0 0.8rem 0.1rem $bg-user-acc-bottom-shadow;
}