@use "app/styles/colors" as *;

.block-wrapper {
  padding: 1rem;
  border-bottom: 0.1rem solid $border-grey;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.head {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.title {
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  color: $text-white;
  text-align: center;
  margin-right: auto;
  margin-bottom: 1.5rem;
}

.arrow {
  height: 1.6rem;
  stroke: $text-white;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;

  &.down {
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
  }
}

.body {
  padding: 0;
  overflow: hidden;
  opacity: 0;
  max-height: 0rem;
  transition: all 0.2s ease-in-out;
  padding-bottom: 1rem;

  &.open {
    max-height: 100rem;
    opacity: 1;
    overflow: visible;
    transition: all 0.2s ease-in-out;
  }
}