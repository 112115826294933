@use "app/styles/colors" as *;

.block-wrapper {
  >label {
    margin: 0;
    padding: 0;
    margin-bottom: 0.6rem;
    margin-left: 0.3rem;
    font-size: 1.5rem;
    color: $text-user-stat-grey;
  }
}