@use "app/styles/colors" as *;

.open-quest-block {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $bg-nft-selected-gray;
  padding: 2rem;
  border-radius: 0.5rem;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.open-quest-text {
  position: absolute;
  top: 3rem;
  width: 15rem;
  text-align: center;
  font-size: 1.7rem;
  color: $text-gold;
  font-weight: 400;
  margin: 0 auto;

}

.open-quest-timer {
  position: absolute;
  bottom: 3rem;
  width: 100%;
  text-align: center;
  font-size: 1.7rem;
  color: $text-gold;
  font-weight: 400;
  margin: 0 auto;
}

.disabled-quest-text {
  text-align: center;
  font-size: 1.7rem;
  color: $text-gold;
  font-weight: 400;
  margin: 0 auto;
}