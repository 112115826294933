@use "app/styles/colors" as *;

.menu-items-wrapper {
  position: absolute;
  right: 0.5rem;
  bottom: 3.8rem;
  padding: 0.2rem;
  background-color: $bg-grey;
  border-radius: 0.5rem;
  opacity: 0.75;
  z-index: 1;
}

.menu-list {
  margin: 0;
  padding: 0.5rem;
  list-style: none;

  >li {
    color: $text-white;
    font-size: 1.5rem;
    padding: 0.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
    background-color: $bg-dark;
    border-radius: 0.5rem;
  }
}