@use "app/styles/colors" as *;

.wrapper {
  margin: 0 auto;
  padding-top: 25rem;
  width: 59.5rem;
  text-align: center;
}


.login-and-create-block {
  background-color: $bg-login-dark;
  border-radius: 0.5rem;
  padding: 2.4rem 2.4rem 4.2rem;
}

.login-block {
  background-color: $bg-login-light-gray;
  border-radius: 0.5rem;
  padding: 4.3rem 3.6rem 2.8rem;
  margin-bottom: 2.1rem;
}

.create-block {
  padding: 0 4.5rem;
}

.button-label {
  font-size: 1.5rem;
  color: $text-grey;
  font-weight: 400;
  margin: 0;
  margin-bottom: 2rem;
  padding-left: 1rem;
  text-align: left;
}

.logos {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 2.5rem;
  padding-bottom: 3.4rem;
}

.logo-color {
  width: 10.4rem;
}

.logo-long {
  width: 33.5rem;
}

.links {
  margin-top: 1.7rem;
  padding: 0 7rem;
  display: grid;
  grid-template-columns: repeat(4, auto);

  >a {
    display: inline-block;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 400;
    cursor: pointer;
    color: $link-gold;

    // &:last-of-type {
    //   color: $text-bluish-gray;
    // }
  }
}