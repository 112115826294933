@use "app/styles/colors" as *;

.disclaimer-wrapper {
  width: 72rem;
  margin: 0 auto;
  margin-top: 16rem;
  background-color: $bg-disclaimer-dark;
  border-radius: 0.5rem;
  padding: 3.5rem 2rem 2rem 2rem;
}

.logos {
  display: flex;
  align-items: flex-start;
  column-gap: 2.5rem;
  padding: 0 3.5rem 2.5rem 3.5rem;
}

.logo-color {
  width: 10.4rem;
}

.logo-long {
  width: 33.5rem;
}

.agreement {
  background-color: $bg-disclaimer-light-gray;
  border-radius: 0.5rem;
  padding: 2.7rem 3rem;
  height: 52rem;
  overflow-y: scroll;
  margin-bottom: 2.1rem;

  /* Width */
  scrollbar-width: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  scrollbar-color: $bg-disclaimer-scrollbar;

  &::-webkit-scrollbar-thumb {
    background: $bg-disclaimer-scrollbar;
    border-radius: 0.5rem;
  }
}

.agree-title {
  margin: 0;
  font-size: 2rem;
  color: $text-white;
  font-weight: 400;
  margin-bottom: 2rem;
}

.agree-text {
  margin: 0;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: $text-white;
  font-weight: 400;
  margin-bottom: 2.8rem;
  letter-spacing: 0.025rem;
}

.check-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 3rem;
}

.check-label {
  margin: 0;
  margin-left: 1.5rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: $text-white;
  font-weight: 400;
  letter-spacing: 0.025rem;
}