@use "app/styles/colors" as *;

.items-container {
  gap: 0.5rem;
  display: grid;
  grid-template-areas:
    "h-body h-head h-hips"
    "h-boots statistic statistic"
    "h-inv1 h-inv2 h-inv3";

  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.h-body,
.h-head,
.h-hips,
.h-boots,
.h-inv1,
.h-inv2,
.h-inv3 {
  width: 100%;
  border-radius: 0.3rem;
  background-color: $bg-nft-gray;
  position: relative;
}

.h-body {
  grid-area: h-body;
}

.h-head {
  grid-area: h-head;
}

.h-hips {
  grid-area: h-hips;
}

.h-boots {
  grid-area: h-boots;
}

.h-inv1 {
  grid-area: h-inv1;
}

.h-inv2 {
  grid-area: h-inv2;
}

.h-inv3 {
  grid-area: h-inv3;
}

// .empty1 {
//   grid-area: empty1;
//   width: 100%;
//   border-radius: 3px;
// }

// .empty2 {
//   grid-area: empty2;
//   width: 100%;
//   border-radius: 3px;
// }

.statistic {
  grid-area: statistic;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  align-items: center;

  .title {
    color: $text-white;
    font-size: 1.3rem;
    margin: 0;
    margin-bottom: 1rem;
    text-align: center;
  }
}