@use "app/styles/colors" as *;

.tabs-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1.5rem;
  max-height: 4rem;
}

.tab {
  background-color: $bg-darker;
  padding: 1rem 1.5rem;
  border: 0.1rem solid $bg-darker;
  border-radius: 0.5rem;
  color: $text-bluish-gray;
  font-size: 1.5rem;
  cursor: pointer;

  &.active {
    background-color: $bg-grey;
    color: $text-gold;
  }

  &.disabled {
    color: $text-grey;
    background-color: $bg-dark;
    cursor: initial;
  }
}

.info-main-container {
  transition: 0.2s;
  display: grid;
  grid-template-columns: 38% 50%;
  padding: 2rem 0;
  overflow: hidden;
  height: calc(100% - 4rem);
  width: 100rem;

  &.small-image {
    transition: 0.2s;
    grid-template-columns: 11% 50%;
  }
}

.info-image-wrapper {
  position: relative;
  border-right: 0.2rem solid $border-nft-active-grey;
  width: 100%;
  height: 100%;
}

.info-active-page {
  overflow: hidden;
}

.info-pages {
  display: flex;
  height: 100%;
  align-items: flex-start;
  flex-direction: row;
  transition: translate;
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.info-wrapper {
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;

  /* Width */
  scrollbar-width: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  scrollbar-color: $bg-disclaimer-scrollbar;

  &::-webkit-scrollbar-thumb {
    background: $bg-disclaimer-scrollbar;
    border-radius: 0.5rem;
  }
}