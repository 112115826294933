@use "app/fonts/fonts" as *;
@use "app/styles/colors" as *;
@use "app/styles/vars" as *;

@mixin screenSize ($fontSize) {

  html,
  body {
    font-size: $fontSize;
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Andale", 'Courier New', Courier, monospace;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: $bg-dark;
  height: 100%;
  font-size: $fontBaseSize;

  /* Width */
  scrollbar-width: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  scrollbar-color: $bg-disclaimer-scrollbar;

  &::-webkit-scrollbar-thumb {
    background: $bg-disclaimer-scrollbar;
    border-radius: 0.5rem;
  }
}


@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
  @include screenSize($fontSize: 55.5%);
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  height: 100%;

  .app-components-wrapper {
    margin-left: 11.5rem;
    height: 100%;
  }
}

button {
  font-family: "Andale", 'Courier New', Courier, monospace;
}

#h2q-tooltip {
  p {
    margin: 0;
    padding: 0;
  }
}