.colors-grid {
  justify-content: center;
  width: 100%;
  height: 1rem;
  display: grid;
  grid-template-columns: repeat(7, 1rem);
  column-gap: 0.4rem;
}

.color-block {
  width: 100%;
  height: 100%;
  border-radius: 0.1rem;
}