@use "app/styles/colors" as *;

.wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0.5rem;
  align-items: stretch;
}

.info-title {
  color: $text-white;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1.5rem;

  &:nth-of-type(2) {
    margin-top: auto;
  }
}

.info-param {
  color: $text-gold;
  font-size: 1.4rem;
  margin-right: 1rem;
}

.info-value {
  color: $text-white;
  font-size: 1.5rem;
  margin: 0;
}

.quest-list {
  list-style: none;
  padding: 0;

  >li {
    border: 0.1rem solid $border-grey;
    padding: 0.5rem;

    &:hover {
      border: 0.1rem solid $text-bluish-gray;
    }
  }
}

.quest-link {
  text-decoration: none;
  font-size: 1.5rem;
  margin: 0;
  margin-top: 0.5rem;

  &>p {
    margin: 0;
    color: $text-grey;
  }

  &>h4 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
    color: $text-white;
  }
}