@use "app/styles/colors" as *;

.staked-block {
  padding: 1rem;
}

.staked-title {
  color: $text-white;
  margin: 0;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 1.8rem;
}

.staked-nfts {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.staked-nft {
  border: 0.1rem solid $border-gold;
}