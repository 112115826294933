@use "app/styles/colors" as *;

.active-quest-block {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $bg-nft-selected-gray;
  padding: 2rem;
  border-radius: 0.5rem;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}