@use "app/styles/colors" as *;

.page-wrapper {
  width: 100%;
}

.info-block {
  width: 98.7rem;
  margin-left: 8.5rem;
  position: relative;
  top: -2.2rem;

  >h3 {
    margin: 0;
    padding: 0;
    color: $text-user-account-header-grey;
    font-size: 1.5rem;
    margin-bottom: 2.1rem;
  }
}

.wallet-info {
  >h3 {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 0.6rem;
    color: $text-user-cryptoacc-label-white;
  }
}

.wallet-bg {
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem;
  background-color: $bg-user-cryptoacc-wallet-grey;
}

.bottom-shadow {
  border-radius: 0.5rem;
  box-shadow: 0 0.8rem 0.1rem $bg-user-acc-bottom-shadow;
}