@use "app/styles/colors" as *;

.user-bar {
  position: relative;
}

.user-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 10rem;
  height: 0;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0 0.8rem;
  overflow: hidden;
  transition: 0.2s all ease-in-out;
  background-color: $sb-user-menu-bg;

  &.open {
    padding: 2rem 0.8rem;
    height: calc((6 * 4rem) + (5 * 0.5rem) + 1.6rem);
    transition: 0.2s all ease-in-out;
  }
}

.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1rem 0.5rem;
  background-color: $sb-user-menu-bg;
  cursor: pointer;
}