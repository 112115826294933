@use "app/styles/colors" as *;

.epoch-colors {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 1rem;
}

.epoch-color {
  border-radius: 0.5rem;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &.active {
    outline: 0.3rem solid $border-gold;
  }

  &:hover {
    transform: scale(1.1);
    transition: all 0.1s ease-in-out;
  }
}

.ids {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 1rem;
}

.id {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 1rem;
  border-right: 0.1rem solid $border-grey;

  &:last-child {
    padding-right: 0;
    border-right: none;

  }
}

.nft {
  width: 5rem;
  height: 5rem;
}

.info {
  color: $text-white;
  font-size: 1.5rem;
}