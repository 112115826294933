@use "app/styles/colors" as *;

.page-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
  min-width: 100rem;
  max-width: 120rem;
  height: 100%;
  padding: 3rem 1rem;
  overflow: hidden;
}

.page-title {
  color: $text-white;
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 2.5rem;
}

.outlet-wrapper {
  margin-top: 2rem;
  margin-bottom: 1rem;
  height: 52rem;
  box-sizing: border-box;
}

.bottom-btns-menu {
  width: 100rem;
  display: grid;
  // grid-template-columns: 8% 0.1rem 8% repeat(7, 8%);
  grid-template-columns: 8% repeat(7, 8%);
  column-gap: 1rem;
}

.active {
  outline: 0.5rem solid $border-nft-active-grey;
}

.item-btn-image,
.nft-btn-image,
.avatar-btn-image {
  background-color: $bg-nft-gray;
  border: 0.1rem solid $bg-darker;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}

.item-btn-image,
.avatar-btn-image {
  cursor: pointer;

  &:hover {
    outline: 0.5rem solid $border-nft-active-grey;
  }
}

.item-btn-image {
  position: relative;
  cursor: pointer;
}

.empty-gallery {
  padding: 5rem;
  color: $text-white;
  font-size: 2rem;
  width: 50rem;
  margin: 0 auto;
  text-align: center;
}