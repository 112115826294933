@use "app/styles/colors" as *;

.page-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  min-height: 100%;
}

.on-top {
  width: 100%;
  padding-top: 3rem;
  padding-left: 5rem;
  position: sticky;
  top: 8rem;
  z-index: 2;
  background-color: $bg-quest-page-gray;
}

.stake-block {
  width: 25rem;
  margin-top: auto;
}

.quest-list {
  margin-left: 5rem;
  max-width: 136rem;
  min-width: 100rem;
}

.page-title {
  color: $text-white;
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 0.5rem;

  >span {
    color: $text-nft-page-header-grey;
    margin-left: 1rem;
  }
}

.nft-info-block {
  display: grid;
  grid-template-columns: 25% auto 20%;
  column-gap: 2rem;
  margin-bottom: 2rem;
  max-width: 136rem;
  min-width: 100rem;
}

.control-item {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  height: 100%;
  align-items: flex-end;
}

.buttons-title {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: $text-grey;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 0.5rem;
}