@use "app/styles/colors" as *;

.side-asset-container {
  width: 33rem;
}

.image {
  margin-top: 5rem;
  padding-bottom: 5rem;
  align-self: center;
  border-bottom: 0.1rem solid $border-grey;
}

.info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tags {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.button {
  width: 33rem;
  margin-top: auto;
}