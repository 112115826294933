@use "app/styles/colors" as *;

.loading {
  position: absolute;
  transform-box: fill-box;
  transform-origin: 50%;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

// .nft-torso {
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   z-index: 1;
// }

// .nft-head {
//   position: absolute;
//   z-index: 1;
// }

// .nft-legs,
// .nft-hip,
// .nft-body,
// .nft-head,
// .nft-acc1,
// .nft-acc2,
// .nft-acc3 {
//   position: absolute;
//   z-index: 1;
// }

.nft-image {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  // padding-bottom: 100%;
}

.fade {
  &::after {
    display: block;
    position: absolute;
    content: "";
    width: 90%;
    height: 15%;
    bottom: 4%;
    left: 5%;
    background-color: $gallery-grey-fade;
    border-radius: 50%;
  }
}