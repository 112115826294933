.reward-items-block {
  &>h3 {
    font-size: 2.1rem;
    color: #dadada;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
  }

  &>ul {
    list-style: none;
    padding: 0;
  }
}