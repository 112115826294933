.btns {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 1.2rem;
}

.btn {
  width: 100%;
  height: 100%;

  &.acitve {
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }

  }
}