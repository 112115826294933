@font-face {
  font-family: "Andale";
  src: url('./AndaleMono.ttf');
  font-style: normal;
}

@font-face {
  font-family: "Glyphter";
  src: url("./Glyphter.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("./Futura-CondensedMedium-04.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Kohinoor";
  src: url("./KohinoorBangla-Medium-03.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Din";
  src: url("./DIN-Condensed-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

#font-initialize-andale {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  font-family: "Andale";
}