.side-nav__toggle-logo {
  margin-top: 0.5rem;
  padding: 3.2rem 2.2rem 2.2rem 3.2rem;
  overflow: hidden;
  height: 7rem;
  min-height: 7rem;

  &.long>img {
    height: 1.4rem;
  }

  &.short>img {
    height: 1.8rem;
  }
}