@use "app/styles/colors" as *;

.description-wrapper {
  margin-bottom: 1.5rem;
}

.text {
  font-size: 1.4rem;
  font-weight: 400;
  color: $text-white;
  margin: 0;
}