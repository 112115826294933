@use "app/styles/colors" as *;

.slider-and-inputs {
  width: 50%;
}

.slider {
  // padding: 0 0.5rem;
  margin-bottom: 4rem;
}

.range-inputs {
  padding: 0 0.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}