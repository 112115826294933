.refresh-icon__wrapper {
  cursor: pointer;
}

.refresh-icon {
  height: 2.6rem;
}

.rotating {
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}