@use "app/styles/colors" as *;

.generators-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 100rem;
  color: $text-white;
}

.inner-wrapper {
  margin-top: 1.2rem;
  margin-left: 6.7rem;
  margin-right: 10rem;
  min-width: 100rem;
  // max-width: 136rem;
}