@use "app/styles/colors" as *;
@use "app/styles/vars" as *;

.side-nav__wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 101;
  width: $sb-width-opened;
  box-sizing: border-box;
  padding-bottom: 0.6rem;
  transition: width 0.15s ease-in-out;
  background-color: $bg-sidebar;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  height: 100%;

  &.closed {
    width: $sb-width-closed;
  }
}