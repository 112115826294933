@use "app/styles/colors" as *;

$base-gap: 1.4rem;

.container {
  margin: 0 auto;
  // margin-left: calc($base-gap / 2);
  padding: 0;
  outline: none;
  border: none;
  background: none;
  position: relative;
  // top: 2.5px;
}

.back {
  display: block;
  content: "";
  opacity: $btn-back-transparency;
  height: calc(100% + $base-gap);
  // width: 100%;
  width: calc(100% - $base-gap / 2);
  position: absolute;
  bottom: calc(-1* $base-gap / 2);
  // transform: translateX(calc(-1* $base-gap / 2));
}

.front-and-text-wrapper {
  position: relative;
  transition: 0.1s;
}

.front-and-text-active {
  cursor: pointer;

  &:active {
    transform: translate(calc(-1* $base-gap / 2), calc($base-gap / 2));
    transition: 0.1s;

    >.text-bg {
      transition: 0.1s;
      width: calc(100% - $base-gap /2);
      height: calc(100% + $base-gap);
    }
  }
}

.front {
  display: block;
  content: "";
  transition: 0.1s;
  transform-origin: bottom left;

  opacity: $btn-middle-transparency;
  // width: calc(100%);
  width: calc(100% - $base-gap/2);
  height: calc(100% + $base-gap);
  position: absolute;
  bottom: 0px;
  // left: 0px;
  right: 0px;
  // transform: translateX(calc($base-gap / 2));
}

.text-bg {
  display: block;
  content: "";
  opacity: $btn-middle-transparency;
  height: calc(100% + $base-gap / 2);
  width: calc(100% - $base-gap);
  position: absolute;
  bottom: 0px;
  // left: 0px;
  transform: translateX(calc($base-gap / 2));
  transition: 0.1s;
}

.text {
  box-sizing: border-box;
  position: relative;
  bottom: calc($base-gap / 3);
  transition: 0.1s;
  opacity: 1;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  >p {
    display: inline-block;
    // padding: 0 1rem;
    margin: 0;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.9rem;
    letter-spacing: 0.055rem;
    color: $text-white;
  }
}