@use "app/styles/colors" as *;

.header {
  padding-top: 2.9rem;
  padding-bottom: 6rem;
  padding-left: 8.5rem;
  background-color: $bg-user-account-grey;

  >svg {
    height: 2.5rem;
  }

  >p {
    margin: 0;
    font-size: 2.5rem;
    color: $text-white;
    letter-spacing: 0.05rem;
  }
}