@use "app/styles/colors" as *;

.add-quest-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  fieldset {
    border: none;

    &.flex {
      display: flex;
      align-items: flex-start;
    }
  }

  h3 {
    margin: 0;
    margin-bottom: 10px;
    color: white;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
  }
}

.value-title {
  display: inline-block;
  color: white;
  font-size: 15px;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  width: 200px;
}

.time-string {
  display: inline-block;
  color: white;
  font-size: 15px;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  margin-left: 10px;
  width: 250px;
}

.num-input {
  color: white;
  font-size: 13px;
  margin: 0;

  padding: 5px 15px;
  outline: none;
  border: 1px solid grey;
  background: none;
  width: 150px;
  text-align: left;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    appearance: initial;
    -moz-appearance: textfield;
  }

}

.str-input {
  color: white;
  font-size: 13px;
  margin: 0;
  padding: 5px 15px;
  outline: none;
  border: 1px solid grey;
  background: none;
  width: 250px;
  text-align: left;

  &.long {
    width: 600px;
  }
}

.str-field-input {
  color: white;
  font-size: 13px;
  margin: 0;
  padding: 5px 15px;
  outline: none;
  border: 1px solid grey;
  background: transparent;
  min-width: 600px;
  max-width: 600px;
  height: 150px;
  text-align: left;
}

.busket-wrapper {
  display: grid;
  grid-template-columns: repeat(9, 60px);
  column-gap: 5px;
  min-height: 100px;
}

.busket-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img {
    position: relative;
  }

  .id {
    color: white;
    font-size: 10px;
  }
}

.clr-btn {
  font-size: 20px;
  width: 100%;
  height: 100%;
  padding: 0;
}

.q-gallery-wrapper {
  padding-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(59, 1fr);
  grid-auto-flow: column;
  column-gap: 3px;
  row-gap: 3px;
  max-height: 500px;
  overflow: auto;
}

.q-gallery-item {
  border: 1px solid black;
}

.item-image {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.image__body {
  width: 100%;
}

.image__head {
  position: absolute;
}

.add-quest-btn {
  font-size: 25px;
  padding: 10px 20px;
}

.table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2rem;
}

.q-del {
  color: $text-quest-param-error;
  font-size: 2rem;
  line-height: 2rem;
}

.q-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: white;
  font-size: 2rem;
}

.q-table {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  color: white;
  border: 1px solid gray;

  th {
    font-size: 1.4rem;
    white-space: nowrap;
  }

  tr {
    &:nth-of-type(2n) {
      background-color: $bg-grey;
    }
  }

  td {
    font-size: 1.3rem;
    white-space: nowrap;
    padding: 0.5rem 1rem;

  }
}

.level {
  width: 5rem;
  text-align: right;
}

.horizontal {
  display: flex;
  flex-direction: row;
}

.selectors {
  display: flex;
  flex-direction: row;
  position: relative;

  &>div {
    margin-right: 1rem;

  }
}

.qst-btns {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  justify-content: flex-start;
  column-gap: 1rem;
}

.load-btn {
  cursor: pointer;
  font-size: 1.8rem;
  color: $text-white;
  border: 0.1rem solid $border-grey;
  border-radius: 0.3rem;
  background-color: $bg-grey;
  display: inline-block;
  padding: 0.5rem 1rem;

  &>input {
    display: none;
  }
}

.deploy-qsts,
.add-form-btn,
.save-btn {
  cursor: pointer;
  font-size: 1.8rem;
  min-width: 20rem;
  color: $text-white;
  border: 0.1rem solid $border-grey;
  border-radius: 0.3rem;
  background-color: $bg-grey;
  display: inline-block;
  padding: 0.5rem 1rem;
}

.deploy-qsts {
  width: 30rem;
  background-color: $bg-bottom-popup-green;
  margin-bottom: 2rem;
}