@use "app/styles/colors" as *;

.top-bar__stats {
  text-align: left;
  min-width: 20rem;
  padding: 0.8rem 2.7rem;
  margin-right: 1rem;
  color: $text-gold;
  font-size: 2rem;
  background-color: $bg-light;
  border-radius: 0.3rem;

  &:last-child {
    margin-right: 0;
  }
}

.h2q {
  color: $text-bluish-gray;
  margin-right: 1.5rem;
}

.qst {
  color: $text-gold;
  margin-right: 1.5rem;
}

.stats-value {
  color: $text-white;
  font-size: 2.5rem;
}

.top-bar__connect {
  padding: 1.1rem 1rem;
  background-color: $bg-light;
  color: $text-gold;
  font-size: 1.8rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 0.3rem;
}