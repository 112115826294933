@use "app/styles/colors" as *;

.back-link {
  color: $text-white;
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  cursor: pointer;
  width: 10rem;
}