@use "app/styles/colors" as *;

.info-title {
  color: $text-white;
  font-size: 1.8rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.info-param {
  color: $text-gold;
  font-size: 1.5rem;
  margin-right: 1rem;
}

.info-value {
  color: $text-white;
  font-size: 1.5rem;
  margin: 0;
}