@use "app/styles/colors" as *;

.rewards-window {
  display: flex;
  flex-direction: column;
}

.scroll-area {
  width: calc(100% + 0.5rem);
  max-height: 50rem;
  overflow-y: scroll;
  margin-bottom: 1rem;
  /* Width */
  scrollbar-width: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  scrollbar-color: $bg-disclaimer-scrollbar;

  &::-webkit-scrollbar-thumb {
    background: $bg-disclaimer-scrollbar;
    border-radius: 0.5rem;
  }
}

.rewards {
  display: grid;
  grid-template-columns: 7rem auto 3rem 3rem 0rem;
  gap: 2rem;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.1rem solid $border-grey;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  font-size: 1.8rem;
  font-weight: 500;
  color: $text-gold;
  margin: 0 0 1rem;
  text-align: center;
  text-transform: uppercase;
}

.quest-name {
  font-size: 1.5rem;
  font-weight: 400;
  color: $text-gold;
  margin: 0 0 0.5rem;
  padding-left: 0.5rem;

  >span {
    color: $text-white;
  }
}

.coins-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  >h4 {
    font-size: 1.5rem;
    font-weight: 400;
    color: $text-gold;
    margin: 0 0 0.5rem;
  }

  >p {
    font-size: 1.5rem;
    font-weight: 400;
    color: $text-white;
    margin: 0 auto;
    height: 5rem;
    padding: 1.75rem 0;
  }
}

.items-block {
  display: flex;
  flex-direction: column;

  >h4 {
    font-size: 1.5rem;
    font-weight: 400;
    color: $text-gold;
    margin: 0 0 0.5rem;
  }
}

.items {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.nft {
  width: 7rem;
  height: 7rem;
  background-color: $bg-nft-gray;
  border: 0.1rem solid $bg-darker;
  border-radius: 0.5rem;
}

.item {
  width: 5rem;
  height: 5rem;
  background-color: $bg-nft-gray;
  border: 0.1rem solid $bg-darker;
  border-radius: 0.5rem;
}