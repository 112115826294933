@use "app/styles/colors" as *;
@use "app/styles/vars" as *;

.top-logo {
  position: relative;
  display: flex;
  height: 22rem;
  max-width: calc(100vw - $sb-width-closed);
  overflow: hidden;

  >div>img {
    width: 100%;
    height: 100%;
  }

  .left {
    flex: none;
    // align-content: flex-start;
    // align-self: flex-start;
  }

  .center {
    min-width: 0;
    flex: 1 1 10%;
    // align-content: center;
    // align-self: flex-start;
  }

  .right {
    flex: none;
    // align-content: flex-end;
    // align-self: flex-start;
  }
}