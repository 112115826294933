@use "app/styles/colors" as *;

.open-quest-block {
  display: flex;
  flex-direction: column;
  background-color: $bg-nft-selected-gray;
  padding: 2rem 2rem 2.5rem 2rem;
  border-radius: 0.5rem;
  height: 100%;
  box-sizing: border-box;
}

.open-quest-text {
  display: inline-block;
  width: 15rem;
  text-align: center;
  font-size: 1.7rem;
  color: $text-gold;
  font-weight: 400;
  margin: auto auto;
  // min-height: 3.5rem;
}

.btn {
  margin-top: auto;
  text-align: center;
}

.btn-style {
  font-size: 2rem !important;
  color: $text-white !important;
  padding: 0 0.5rem !important;
}