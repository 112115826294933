@use "app/styles/colors" as *;

.modal-items__text {
  padding: 1rem;
  margin: 0;
  font-size: 2rem;
  color: $text-white;
}

.modal-items__buttons {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}