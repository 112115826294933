@use "app/styles/colors" as *;

.side-nav__link {
  text-decoration: none;
  border-radius: 0.5rem;
  margin-bottom: 2.4rem;

  &.link-bottom {
    margin-top: auto;
  }

  &.selected {
    background-color: $sb-selected-bg;

    svg {
      fill: $sb-icon-white !important;
      stroke: $sb-icon-white !important;
      transition: all 0.15s ease-out;
    }
  }
}

.link__body,
.disabled-link__body {
  display: grid;
  grid-template-columns: 9rem minmax(0, auto);
  align-items: center;
  padding: 1rem 0.7rem;
  border-radius: 0.5rem;
  overflow: hidden;
}

.link__body {
  &:hover {
    background-color: $sb-selected-bg;

    .link__logo>svg {
      transition: all 0.1s ease-in-out;
    }
  }
}

.link__logo {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 2.8rem;

  svg {
    height: 100%;
  }
}

.link__text {
  padding: 0;
  margin: 0;
  color: $text-white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 2rem;
  opacity: 1;
  transition: all 0.15s ease-in-out;
  width: auto;

  &.closed {
    transition: all 0.15s ease-in-out;
    opacity: 0;
    margin-left: 0;
    width: 0;
  }
}