@use "app/styles/colors" as *;

.reload-btn {
  cursor: initial;
  opacity: 0.6;

  background-color: $bg-grey;
  width: 5rem;
  height: 3.5rem;
  padding: 0.55rem;
  outline: none;
  border-radius: 0.3rem;
  border: 0.2rem solid $border-grey;
  transition: all 0.1s ease-in-out;

  &.active {
    opacity: 1;
    cursor: pointer;

    &:active {
      // transform: scaleX(1.02);
      transform: translate(-2%, 2%);
      box-shadow: 0.1rem 0.1rem 0.5rem $text-white;
      transition: all 0.1s ease-in-out;
    }
  }


}

.reload-image {
  height: 100%;
  opacity: 0.5;

  &.rotating {
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}