@use "app/styles/colors" as *;

.round-border-and-bg {
  background-color: $bg-assets-grey;
  background-image: url("./assets-gradient-3px.png");
  background-size: contain;
  box-sizing: border-box;
  border: 0.1rem solid $bg-darker;
  height: 100%;
  border-radius: 1.2rem;
}

.gallery-main-item-wrapper {
  border: 0.5rem solid $bg-darker;
  background-color: $bg-darker;
  transition: transform 0.2s ease-in-out;

  &.chosen {
    box-shadow: 0 0.5rem 1rem -0.5rem $border-gold;
  }

  &.stacked {
    outline: 0.1rem solid $border-gold;
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-0.5rem);
    box-shadow: 0 0.5rem 1rem -0.5rem $border-gold;
    transition: transform 0.2s ease-in-out;
  }
}

.item-bottom-btns-wrapper {
  position: relative;
  background-color: $bg-darker;
  padding: 1.4rem 0.5rem;
  min-height: 6.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-amount {
  position: absolute;
  padding: 0;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: $text-white;
  font-size: 2.7rem;
}