@use "app/styles/colors" as *;

.sidebar-btn-block {
  background-color: $bg-disclaimer-light-gray;
  border-radius: 0.5rem;
  padding: 3.6rem 0;
  cursor: pointer;
  text-align: center;
}

.login-icon {
  height: 2rem;
  width: 2rem;
  vertical-align: middle;
  transition: all 0.15s ease-in-out;
  transform: translateX(0);

  &.login-icon-closed {
    transition: all 0.15s ease-in-out;
    transform: translateX(3rem);
  }
}

.login-text {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  opacity: 1;
  margin-left: 2.5rem;
  color: $text-white;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  transition: all 0.15s ease-in-out;
  transform: scaleX(100%);

  &.login-text-closed {
    opacity: 0;
    margin-left: 0;
    transform: scaleX(0%);
  }
}