@use "app/styles/colors" as *;

.select-wrapper {
  position: relative;
  width: 100%;
  height: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
}

.btn-block {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.select-header {
  margin: 1rem 0 2rem;
  padding: 0;
  font-size: 1.8rem;
  color: $text-white;
  font-weight: 400;
  letter-spacing: 0.05rem;
}

.quest-info {
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
  color: $text-white;

  &:last-of-type {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.quest-list {
  box-sizing: border-box;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
  margin-bottom: calc(5rem + 2rem);

  /* Width */
  scrollbar-width: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  scrollbar-color: $bg-disclaimer-scrollbar;

  &::-webkit-scrollbar-thumb {
    background: $bg-disclaimer-scrollbar;
    border-radius: 0.5rem;
  }
}

.qust-element {
  padding: 0.5rem;
  background-color: $bg-user-acc-label-lighter;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;

  >p {
    padding: 0;
    margin: 0;
    margin-bottom: 0.3rem;
    font-size: 1.5rem;
    color: $text-white;
  }
}

.h2q {
  color: $text-bluish-gray;
  margin-right: 1.5rem;
}

.qst {
  color: $text-gold;
  margin-right: 1.5rem;
}

.error-message {
  color: $text-quest-param-error;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
  text-align: center;
}