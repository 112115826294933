@use "app/styles/colors" as *;

.add-generator-banner {
  position: absolute;
  left: 23rem;
  top: 18%;
  width: 55%;
  display: grid;
  grid-template-columns: 10% 85%;
  column-gap: 1.9rem;
  cursor: pointer;

  >div {
    >h2 {
      font-family: "Futura";
      margin: 0;
      padding: 0;
      // font-size: 5rem;
      font-size: 2.2vw;
      color: $text-white;
      letter-spacing: 0.3rem;
      margin-bottom: 1rem;
    }

    >p {
      margin: 0;
      padding: 0;
      // font-size: 1.3rem;
      font-size: 0.7vw;
      color: $text-white;
    }
  }

  >svg {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}