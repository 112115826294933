@use "app/styles/colors" as *;

.modal-title {
  font-size: 2.1rem;
  padding: 1.5rem 2.5rem;
  text-align: center;
  color: $modalFontColor;
  background-color: $modalBg;
  opacity: 0.75;
  margin-bottom: 1rem;
}