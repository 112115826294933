@use "app/styles/colors" as *;

.season-header {
  margin: 1rem 0 3rem;
  padding: 0;
  font-size: 2rem;
  color: $text-white;
  font-weight: 400;
  letter-spacing: 0.05rem;
}

.season-title,
.season-descr {
  width: 100%;
  margin-bottom: 2rem;
  padding: 1.4rem 1.5rem;
  background-color: $bg-user-acc-label-darker;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;

  &.editable {
    background-color: $bg-user-acc-label-lighter;
  }

  &:hover {
    border: 0.1rem solid $border-user-account-active-input;
  }

  &:has(input:focus) {
    border: 0.1rem solid $border-user-account-active-input;
  }

  >textarea,
  >input {
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: $text-white;
    font-weight: 400;
    letter-spacing: 0.05rem;
    width: 90%;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $text-user-account-placeholder;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $text-user-account-placeholder;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $text-user-account-placeholder;
    }
  }

  >textarea {
    height: 10rem;
    resize: none;
  }
}