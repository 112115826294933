@use "app/styles/colors" as *;
@use "app/styles/vars" as *;

$sb-gap: 3rem;

.wrapper-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  background-color: $bg-dark;
  box-sizing: border-box;
}

.outlet-wrapper {
  margin-top: 8rem;
  height: calc(100% - 8rem);
  // display: grid;
  // grid-template-columns: 100% 0;
  // column-gap: 0;
  // transition: all 0.5s ease-in-out;

  width: 100%;
  transition: width 0.5s ease-in-out;

  &.with-sidebar {
    width: calc(100% - $sb-right-width - $sb-gap);
    transition: width 0.5s ease-in-out;
  }

  // &.with-sidebar {
  // column-gap: 3rem;
  // grid-template-columns: calc(100% - 37rem - 3rem) 37rem;
  // transition: all 0.5s ease-in-out;
  // }
}