@use "app/styles/colors" as *;

.filter-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: $bg-darker;
  transform: scaleY(0);
  opacity: 0;
  transform-origin: top;
  transition: all 0.1s ease-in-out;
  z-index: 20;

  &.shown {
    transform: scaleY(1);
    opacity: 1;
    transition: all 0.1s ease-in-out;
  }
}