.rotating {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0;
  padding: 0;
  border: 0.5rem solid rgba(0, 0, 0, .2);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

.center {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 6;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}