@use "app/styles/colors" as *;

.item-link {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
}

.fade {
  &::after {
    display: block;
    position: absolute;
    content: "";
    width: 90%;
    height: 15%;
    bottom: 1.5rem;
    left: 5%;
    background-color: $gallery-grey-fade;
    border-radius: 50%;
  }
}