@use "app/styles/colors" as *;

.close-item-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  outline: none;
  background-color: $bg-grey;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  border: 0.1rem solid $bg-grey;
  cursor: pointer;

  &>p {
    display: inline;
    font-size: 1.4rem;
    font-weight: 500;
    color: $text-grey;
    text-align: center;
    padding: 0;
    margin: 0;
    text-transform: capitalize;
  }

  &>svg {
    display: inline-block;
    height: 1.6rem;
    stroke: $text-blue;
  }

}