@use "app/styles/colors" as *;

.generator-item {
  position: relative;
  background-color: $bg-darker;
}

.generator-link {
  color: inherit;
  text-decoration: inherit;
}

.generator-image {
  position: relative;
  cursor: default;
  overflow: hidden;
  z-index: 1;
  border-radius: 1.2rem;
  border: 0.5rem solid $bg-darker;
  box-sizing: border-box;
}

.image-icon {
  position: absolute;
  left: 0.7rem;
  top: 0.7rem;
  height: 1.7rem;
  z-index: 1;
}

.image-banner {
  position: relative;
  width: 100%;
  z-index: 1;
}

.count {
  position: absolute;
  display: inline-block;
  font-size: 2.5rem;
  color: $text-white;
  left: 0.7rem;
  bottom: 0.7rem;
  z-index: 11;
  margin: 0;

  &>span {
    font-size: 1.5rem;
    color: $text-gold;
    margin-left: 0.2rem;
  }
}

.generator-back-item {
  padding: 1.5rem 2rem;
  cursor: default;

  >h3 {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    color: $text-white;
    margin-bottom: 2rem;
  }

  >p {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    color: $text-white;
    margin-bottom: 2rem;
  }

  >ul {
    list-style: none;
    padding: 0;

    >li {
      margin: 0;
      padding: 0;
      font-size: 1.5rem;
      color: $text-white;
      margin-bottom: 1rem;

      >span {
        color: $text-gold;
      }
    }
  }
}

.hover-frame {
  position: absolute;
  left: 50%;
  top: 50%;
  z-Index: 10;
  width: "100%";
  transform: translate(-50%, -50%) scaleY(1.23);
  cursor: pointer;
  z-index: 10;
}