@use "app/styles/colors" as *;

.generator-link {
  color: inherit;
  text-decoration: inherit;
}

.add-generator {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;

  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 45%;
    left: 0%;
    bottom: 0%;
    opacity: 0.05;
    background-color: rgb(255, 255, 255);
    filter: blur(10rem);
  }

  &::after {
    display: block;
    position: absolute;
    content: "Add your heroes";
    font-size: 2.2rem;
    text-align: center;
    color: $text-white;
    width: 90%;
    height: calc(15% - 3.4rem);
    bottom: 5%;
    left: 5%;
    padding: 1.2rem 0 1.2rem 0;
    background-color: $fade-generator-selector;
    border-radius: 50%;
  }

  >button>svg {
    opacity: 0.5;
  }

  &:hover {
    >button>svg {
      opacity: 1;
    }
  }
}

;