@use "app/styles/colors" as *;

.title {
  font-size: 1.8rem;
  font-weight: 400;
  color: $text-gold;
  margin-top: 0;
  margin-bottom: 1rem;
}

.icon {
  height: 1.5rem;
  vertical-align: -0.2rem;
  margin-left: 0.5rem;
  cursor: pointer;
}