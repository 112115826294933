@use "app/styles/colors" as *;

.error-page-wrapper {
  margin: 0 auto;
  padding: 5rem 10rem;
  color: $text-white;
}

.error-title {
  font-size: 5rem;
}

.error-text {
  font-size: 2.5rem;
}