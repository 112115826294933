@use "app/styles/colors" as *;

.login-to-account__ {

  &wrapper {
    // font-family: 'Inter';
    position: relative;
    margin: 0 auto;
    padding-top: 25rem;
    width: 62rem;
    text-align: center;

    >a {
      position: absolute;
      top: 22rem;
      left: 0;
      color: $text-white;
      font-size: 1.8rem;
      cursor: pointer;
      text-decoration: none;

      >span {
        display: inline-block;
        vertical-align: 0.2rem;
        padding-right: 0.5rem;
      }
    }

    >h2 {
      color: $text-white;
      font-size: 3.5rem;
      font-weight: 400;
      padding: 0;
      margin: 0;
      text-transform: uppercase;
      margin-bottom: 2.5rem;
    }

    >p {
      color: $text-bluish-gray;
      font-size: 1.4rem;
      font-weight: 400;
      padding: 0;
      margin: 0;
      margin-bottom: 5rem;
    }

    .seed-words-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 18rem);
      justify-content: center;
      column-gap: 2rem;
      row-gap: 2rem;
      margin-bottom: 5rem;

      .seed-word-input {
        border: 0.2rem solid $border-grey;
        border-radius: 0.3rem;

        >input {
          text-align: center;
          margin: 0;
          padding: 0;
          width: 100%;
          border: none;
          outline-width: 0;
          align-items: center;
          border-width: 0;
          color: $text-white;
          background-color: transparent;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 700;
          height: 3.2rem;
          line-height: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
}