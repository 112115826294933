@use "app/styles/colors" as *;

.check-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 2.3rem;
  height: 2.3rem;
  background: transparent;
  border: 0.2rem solid $border-disclaimer-white;
  border-radius: 0.2rem;

  &.checked {
    background: $bg-checkbox-checked;
  }

  &:hover {
    cursor: pointer;
  }
}

.icon-check::before {
  display: inline-block;
  font-family: 'Glyphter';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\0041';
  color: $text-white;
  font-size: 1.2rem;
}