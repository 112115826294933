@use "app/styles/colors" as *;

.top-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 2.2rem;
}

.h2q {
  color: $text-bluish-gray;
  margin-left: 0.5rem;
}

.quest-show-btn {
  width: auto;
}