@use "app/styles/colors" as *;

.square {
  padding: 1rem;
  border: 0.2rem solid $border-grey;
  border-radius: 0.3rem;
  position: relative;
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  row-gap: 1.2rem;

  &.active {
    outline: 0.1rem solid $border-gold;
  }
}

.square-remove {
  position: absolute;
  right: -1rem;
  top: -1rem;
  z-index: 1;
  border-radius: 50%;
}

.square-color {
  position: absolute;
  right: -1rem;
  bottom: -1rem;
  z-index: 1;
  width: 2rem;
  height: 2rem;
}