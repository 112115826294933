@use "app/styles/colors" as *;

.wrapper {
  position: relative;
  background-color: $bg-darker;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 6.3rem;
  padding: 1.4rem 0.5rem;

  &.selected {
    background-color: $bg-nft-selected-gray;
  }
}

.stacked-info {
  font-size: 1.4rem;
  padding: 0 0.5rem;
  margin: 0;
  color: $text-gold;
  text-align: center;
  width: 100%;

  &.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  >p {
    font-size: 1.4rem;
    margin: 0;
    color: $text-gold;
    text-align: center;
  }
}

.locked-time {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  color: $text-bluish-gray;
  text-align: center;
  transform-origin: right;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;

  &.shown {
    transition: all 0.2s ease-in-out;
    transform: scaleX(100%);
    cursor: pointer;
  }
}

.value {
  color: $text-white;
  font-size: 1.8rem;
  padding: 0;
  margin: 0;
}

.h2q {
  color: $text-bluish-gray;
  margin-right: 0.5rem;
}