@use "app/styles/colors" as *;

.top-info-wrapper {
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 1rem;
}

.top-info {
  background-color: $bg-light;
  border-radius: 0.3rem;
  border: 0.1rem solid $border-black;
  padding: 0.5rem 1rem;
  min-height: 7.5rem;
  box-sizing: border-box;

  >h3 {
    color: $text-grey;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.top-info__value {
  font-size: 1.6rem;
  color: $text-white;
  margin: 0;
}

.h2q {
  color: $text-bluish-gray;
  margin-left: 1.5rem;
}

.qst {
  color: $text-gold;
  margin-left: 1.5rem;
}

.quest-info-wrapper {
  display: flex;
  min-height: 3.5rem;
  gap: 2rem;
  flex-direction: row;
  align-items: center;
}