@use "app/styles/colors" as *;

.list-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  row-gap: 1rem;
}

.quest-list-title {
  color: $text-white;
  margin-top: 2rem;
  margin-bottom: 0rem;
  font-size: 2rem;
  text-transform: uppercase;
}

.no-data {
  font-size: 2rem;
  color: $text-white;
  text-align: center;
}