@use "app/styles/colors" as *;

.readonly-string-block {
  display: flex;
  align-items: center;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;

  >p {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: $text-white;
    font-weight: 600;
    letter-spacing: 0.05rem;
  }

  &.link {
    >p {
      cursor: pointer;

      &:hover {
        color: $text-bluish-gray !important;
      }
    }

  }
}

.icon-copy {
  margin-left: auto;
  cursor: pointer;
  height: 2.6rem;
  transition: all ease-out 0.1s;
  fill: $icon-color-grey;
  stroke: $icon-color-grey;

  &:hover {
    fill: $icon-color-white;
    stroke: $icon-color-white;
  }

  &.active {
    transition: all ease-out 0.1s;
    transform: scale(1.2);
  }
}