@use "app/styles/colors" as *;

.not-found-page__ {
  &wrapper {
    margin: 0 auto;
    padding: 5rem 10rem;
    color: $text-white;
  }

  &title {
    font-size: 5rem;
  }

  &text {
    font-size: 2.5rem;
  }
}